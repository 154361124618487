<div class="container-fluid p-2 mobile_mail" style="background-color: #3498db">
    <div class="container">
        <div class="row">

            <div class="col-lg-6">
                <div style="float:left;color:white">info@hydgourisevasangham.com</div>
            </div>
            <div class="col-lg-6">
                <!--<div style="float: right; color: white">Yellapu JaganMohan</div>-->
            </div>

        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg mt-5 navbar-light bg-light"
     [class.active]="classApplied"
     [ngClass]="{'sticky': isSticky}">
    <div class="container">
        <div class="mobile_menu">
            <div class="row">
                <div class="col-lg-6 col-xs-9 col-9">
                    <a class="navbar-brand pb-4" routerLink="/">
                        <img src="../../../../assets/img/Gouri.jpeg" class="gavara_img" />
                        <span class="gd">&nbsp;&nbsp;GAVARA's Dairy</span>
                    </a>
                </div>
                <!--<div class="col-lg-6">
                    <button class="  reg" (click)="Registration()">Registration</button>
                </div>-->
                <br />
                <div class="col-lg-6 col-xs-3 col-3">
                    <button class="navbar-toggler burg menubutton" type="button" (click)="toggleClass()">
                        <span class="burger-menu">
                            <span class="top-bar"></span>
                            <span class="middle-bar"></span>
                            <span class="bottom-bar"></span>
                        </span>
                    </button>
                </div>
            </div>

                <!--<button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>-->


        </div>
        <div class="collapse navbar-collapse"  id="navbarSupportedContent" style=" margin-top:0%;">


            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
                <li *ngIf="!logindata" class="nav-item"><span class="nav-link" routerLink="/Registration">Registration</span></li>
                <li *ngIf="!logindata" class="nav-item"><span class="nav-link" routerLink="/Login">Login</span></li>
                <li class="nav-item">
                    <div class="avatar-container" (click)="logout()">
                        <a *ngIf="logindata?.Name" routerLink="/Profile" class="nav-link circle-avatar">
                            <h2 class="tooltiptext fontt h2">{{logindata.Name | slice:0:1}}</h2><br />
                            <!--<p class="logout"  onclick="logout()">Logout</p>-->
                            <span class="tooltiptext"></span>
                        </a>
                        <ul class="logout-menu">
                            <li class="nav-item" *ngIf="logindata">
                                <a (click)="logout()" class="nav-link" style="cursor:pointer;margin-top:-30px">Logout</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--<li *ngIf="logindata" class="nav-item"><span class="nav-link" (click)="logout()" style="cursor:pointer">Logout</span></li>-->
                <li class="nav-item"><span class="nav-link" routerLink="/contact">Contact Us</span></li>
                <!--<li class="nav-item" >
                <a class="nav-link" routerLink="/lazy-loading">
                Lazy Loading
                </a>
                </li>-->
            </ul>


            <!--<ul class="navbar-nav ml-auto">
        <li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/Registration">Registration</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/Login">Login</span></li>-->
            <!--<li *ngIf="!logindata" class="nav-item"><span class="nav-link" routerLink="/Registration">Registration</span></li>-->
            <!--<li *ngIf="!logindata" class="nav-item"><span class="nav-link" routerLink="/Login">Login</span></li>
    <li class="nav-item">
        <div class="avatar-container">
            <a *ngIf="logindata?.Name" routerLink="/Profile" class="nav-link circle-avatar">
                <h2 class="tooltiptext fontt h2">{{logindata.Name | slice:0:1}}</h2>
                <span class="tooltiptext"></span>
            </a>
            <ul class="logout-menu">
                <li class="nav-item" *ngIf="logindata">
                    <a (click)="logout()" class="nav-link" style="cursor:pointer">Logout</a>
                </li>
            </ul>
        </div>
    </li>-->
            <!--<li class="nav-item"><span class="nav-link" routerLink="/Login">Login</span></li>-->
            <!--<li class="nav-item"><span class="nav-link" routerLink="/contact">Contact Us</span></li>
    </ul>-->

            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
                <li  *ngIf="!logindata" class="nav-item"><span class="nav-link" routerLink="/Registration">Registration</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/contact">Contact Us</span></li>
                <li *ngIf="!logindata" class="nav-item"><span class="nav-link" routerLink="/Login">Login</span></li>
                <li class="nav-item">
                    <div class="avatar-container">
                        <a *ngIf="logindata?.Name" routerLink="/Profile" class="nav-link circle-avatar">
                            <h2 class="tooltiptext fontt h2">{{logindata.Name | slice:0:1}}</h2>
                            <span class="tooltiptext"></span>
                        </a>
                        <ul class="logout-menu logot">
                            <li class="nav-item" *ngIf="logindata">
                                <a (click)="logout()" class="nav-link">Logout</a>
                            </li>
                        </ul>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</nav>
<style>
   /* .gavara {
        font-size: 40px;
    }*/
    .logout-menu {
        border: none !important;
    }
    @media only screen and (max-width:600px){
      /*  .gavara {
            font-size: 15px;
            margin-left: -5%;
        }*/
        .logout-menu {
            border: none !important;
        }
      /*  .btn{
            padding: 5px;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            margin-top: 5px;
            cursor: pointer;
            background-color: #3498db;
            color: #fff;
            border: 1px solid #3498db;
            border-radius: 5px;
            float: right;
        }*/
    }
</style>
