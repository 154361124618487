import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, public router: Router) { }

    //public onClick(elementId: string): void { 
    //    this.viewportScroller.scrollToAnchor(elementId);
    //}

    ngOnInit() {
    }

    onClick() {
        this.router.navigate(['/searchlist']);
    }

}
