
<div class="container mt-5">
    <div class="p-5">
        <div id="clickonEdit">

            <form>
                <fieldset [formGroup]="Form">
                    <legend>
                        <p class="legend mgt-5">&nbsp;Personal Details</p>
                    </legend>
                    <div class="row">
                        <div class="col-lg-8">


                            <div class="row">
                                <!--<div class="form-group">
                                    <label><b> Surname<span style="color:red">*</span></b></label>
                                    <input class="form-control" formControlName="Surname" type="text" placeholder="Surname ">
                                    <small style="color:red" class="text-danger" *ngIf="Form.controls.Surname.touched && Form.controls.Surname.errors?.required">Surname is required</small>
                                </div>-->
                                <div class="col-lg-4">
                                    <label><b> Surname<span style="color:red">*</span></b></label>
                                </div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <select class="form-control" formControlName="Surname" (change)="onSurnameSelection($event.target.value)">
                                            <option value="">Select Surname</option>
                                            <option *ngFor="let surname of surnamedata" [value]="surname.Surname">{{ surname.Surname }}</option>
                                        </select>
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Surname.touched && Form.controls.Surname.errors?.required">Surname is required</small>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <label><b>Full Name<span style="color:red">*</span></b></label>
                                </div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Name" type="text" placeholder="Name ">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Name.touched && Form.controls.Name.errors?.required">Name is required</small>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label><b>Date Of Birth </b></label>
                                    <input class="form-control" formControlName=" DateOfBirth" type="text" placeholder=" Date Of Birth ">
                                    <small style="color:red" class="text-danger" *ngIf="Form.controls.Dno.touched && Form.controls.Dno.errors?.required">Designation is required</small>
                                </div>
                            </div>-->

                            <div class="row">
                                <div class="col-lg-4">
                                    <label><b> Fathers Name<span style="color:red">*</span></b></label>
                                </div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Father" type="text" placeholder="Fathers Name ">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Father.touched && Form.controls.Father.errors?.required">Fathers Name is required</small>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <label><b> Mobile Number<span style="color:red">*</span></b></label>
                                </div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Mobile" type="text" inputmode="numeric" pattern="[0-9]*" maxlength="10" placeholder="Mobile Number ">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Mobile.touched && Form.controls.Mobile.errors?.required">Mobile Number is required</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <label><b>Alternative Mobile</b></label>
                                </div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="AlternativeMobile" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10)" maxlength="10" placeholder="Alternative Mobile ">
                                        <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.AlternativeMobile.touched && Form.controls.AlternativeMobile.errors?.required">Alternative Mobile is required</small>-->
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-lg-4">  <label><b>Email<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Email" type="text" placeholder="Email ">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Email.touched && Form.controls.Email.errors?.required">Email is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <label><b>Current Address<span style="color:red">*</span></b></label>
                                </div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Address" type="text" placeholder="Current Address ">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Address.touched && Form.controls.Address.errors?.required">Current Address is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4"> <label><b>Native Address <span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="OriginalAddress" type="text" placeholder="Native Address ">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.OriginalAddress.touched && Form.controls.OriginalAddress.errors?.required">Native Address is required</small>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4"> <label><b> Occupation </b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="Occupation" type="text" placeholder="Occupation ">
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-lg-4"> <label><b>Since How Long Staying Here</b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="StayDuration" type="text" placeholder="Since How Long Staying Here ">
                                    </div>
                                </div>
                            </div>

                            <!--<div class="row">
                                <div class="col-lg-4">  <label><b> Gender<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                <div class="form-group">
                                    <select class="form-control" formControlName="GenderName">
                                        <option value="">Select Gender</option>

                                        <option *ngFor="let gender of genderdata" [value]="gender.GenderID" style="cursor:pointer">{{ gender.GenderName}}</option>
                                    </select>
                                    <small style="color:red" class="text-danger" *ngIf="Form.controls.GenderName.touched && Form.controls.GenderName.errors?.required">Gender is required</small>
                                </div>
                                </div>
                            </div>-->
                            <div class="row">
                                <div class="col-lg-4">  <label><b>Marital<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <select class="form-control" formControlName="MaritalStatus">
                                            <option value="">Select Marital Status</option>

                                            <option *ngFor="let marital of Statusdata" [value]="marital.StatusID" style="cursor:pointer">{{ marital.MaritalStatus}}</option>
                                        </select>
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.MaritalStatus.touched && Form.controls.MaritalStatus.errors?.required">MaritalStatus is required</small>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="row">
                                <div class="col-lg-4">   <label><b>Spouse Name</b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Spouse" type="text" placeholder="Spouse Name">
                                    </div>
                                </div>
                            </div>-->
                            <div class="row">
                                <div class="col-lg-4">  <label><b>Age<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Age" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 3)" [maxlength]="3" placeholder="Age">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Age.touched && Form.controls.Age.errors?.required">Age is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">    <label><b>Date Of Birth<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="DateOfBirth" type="date" name="date" placeholder="Date Of Birth">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4"> <label><b>Aadhar Number<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Idproof" type="text"  oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 12)" [maxlength]="12"  placeholder="Adhar Number">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Idproof.touched && Form.controls.Idproof.errors?.required">Adhar Number is required</small>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label><b>Aadhar Image<span style="color:red">*</span></b></label>
                                        <div class="images-container">
                                            <div class="m-image-wrapper">
                                                <img [src]="HomeUrl+IdProofImage" *ngIf="IdProofImage" style="cursor: pointer; height: 180px; width: 17rem;">
                                            </div>
                                        </div>
                                        <br />
                                        <input formControlName="IdProofImage" type="file" (change)="detectFiles1($event)" class="m-img-upload-btn" accept="image/*" />

                                        <small style="color: red" class="text-danger" *ngIf="Form.controls.IdProofImage.touched && Form.controls.IdProofImage.errors?.required">Image is required</small>
                                    </div>
                                </div>

                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label><b>Profile IMG<span style="color:red">*</span></b></label>
                                        <div class="images-container">
                                            <div class="m-image-wrapper">
                                                <img [src]="Image" *ngIf="Image" style="cursor: pointer; height: 180px; width: 17rem;">
                                            </div>
                                        </div>
                                        <br />
                                        <input formControlName="Image" type="file" (change)="detectFiles2($event)" class="m-img-upload-btn" accept="image/*" />

                                        <small style="color: red" class="text-danger" *ngIf="Form.controls.Image.touched && Form.controls.Image.errors?.required">Image is required</small>
                                    </div>
                                </div>

                            </div>




                            <!--<div class="col-lg-4 mt-1">
                                <label for="image">Upload Image:</label><br />
                                <input type="file" id="image" name="image" accept="image/*">
                                <input input type="file" (change)="detectFiles2(input2)" accept="image/*" #input2 class="m-img-upload-btn" />
                            </div>-->
                        </div>
                        <div class="col-lg-4"></div>
                    </div><br />
                </fieldset>
                <br /><br />
                <fieldset [formGroup]="Form">
                    <legend>
                        <p class="legend mgt-5">&nbsp;Address Details</p>
                    </legend>

                    <div class="row">
                        <div class="col-lg-8">

                            <div class="row">
                                <div class="col-lg-4">  <label><b> Address<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <!--<input class="form-control" formControlName="Address" type="text" placeholder="Address">-->
                                        <input class="form-control" formControlName="Address" type="text" placeholder="Address">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Address.touched && Form.controls.Address.errors?.required">Address is required</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">    <label><b> State Name<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <select class="form-control" formControlName="State" (change)="onStateChange($event.target.value)">
                                            <option value="">Select State</option>
                                            <!-- Loop through the statedata to populate the dropdown options -->
                                            <option *ngFor="let state of statedata" [value]="state.StateId" style="cursor:pointer">{{ state.StateName}}</option>
                                        </select>
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.State.touched && Form.controls.State.errors?.required">State Name is required</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">   <label><b> District Name<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <select class="form-control" formControlName="district" (change)="onStateChange1($event.target.value)" style="cursor:pointer">
                                            <option value="">Select District</option>
                                            <!-- Loop through the statedata to populate the dropdown options -->
                                            <option *ngFor="let district of districtdata" [value]="district.DistrictID">{{district.DistrictName}}</option>
                                        </select>
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.district.touched && Form.controls.district.errors?.required">district Name is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">   <label><b> City Name<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <select class="form-control" formControlName="City" (change)="onStateChange2($event.target.value)" style="cursor:pointer">
                                            <option value="">Select City</option>
                                            <!-- Loop through the statedata to populate the dropdown options -->
                                            <option *ngFor="let cities of citiesdata" [value]="cities.CityId">{{cities.CityName}}</option>
                                        </select>
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.City.touched && Form.controls.City.errors?.required">city Name is required</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">  <label><b> Pincode<span style="color:red">*</span></b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="Pincode" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 6)" [maxlength]="6" placeholder="Pincode">
                                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Pincode.touched && Form.controls.Pincode.errors?.required">Pincode is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4"></div>
                    </div>
                </fieldset>
                <br /><br />
                <fieldset [formGroup]="Form">
                    <legend>
                        <p class="legend mgt-5">&nbsp;Company Details</p>
                    </legend>
                    <div class="row">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-6"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-4">    <label><b> Company Name</b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="CompanyName" type="text" placeholder="Company Name">
                                        <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.CompanyName.touched && Form.controls.CompanyName.errors?.required">Company Name is required</small>-->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4"> <label><b> Company Address</b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="CompanyAddress" type="text" placeholder="Company Address">
                                        <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.CompanyAddress.touched && Form.controls.CompanyAddress.errors?.required">Company Address is required</small>-->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4"> <label><b> Company City</b></label></div>
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="CompanyCity" type="text" placeholder="Company City">
                                        <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.CompanyCity.touched && Form.controls.CompanyCity.errors?.required">Company City is required</small>-->
                                    </div>
                                </div>
                            </div>

                            <!--<div class="col-lg-4 mt-1">
                                <div class="form-group">
                                    <label><b> Company Pincode<span style="color:red">*</span></b></label>
                                    <input class="form-control" formControlName="CompanyPincode" type="text" placeholder=" Company Pincode">
                                    <small style="color:red" class="text-danger" *ngIf="Form.controls. CompanyPincode.touched && Form.controls. CompanyPincode.errors?.required"> Company Pincode is required</small>
                                </div>
                            </div>-->

                        </div>
                        <div class="col-lg-4"></div>
                    </div>

                </fieldset>

                <div class="row">
                    <div class=" col-lg-10"></div>
                    <div class="col-lg-2 mt-3">
                        <input type="Submit" value="Submit" class="btn btnn" [ngClass]="{'btn-primary': buttonClicked, 'border': buttonClicked}" (click)="onSubmit(Form.value)">
                    </div>
                </div>
                <!--<div class="row">
                    <div class="col-lg-10"></div>
                    <div class="col-lg-2 mt-3">
                        <input type="Submit" value="Update" class="btn" [ngClass]="{'btn-primary': buttonClicked, 'border': buttonClicked}" (click)="onSubmit(Form.value)">
                    </div>
                </div>-->

            </form>

        </div> <br />
    </div><br />
</div>
