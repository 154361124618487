<div class="container">
    <div class="row">
        <div class="col-lg-8 searchmobile">
            <input [(ngModel)]="searchFilter" class="input" placeholder="Search by Name, Mobile" (input)="onSearchInput($event)">
            <span class="mleft-4"><i class="ml-3 fas fa-search "></i> </span>
        </div>
        <div class="col-lg-1 col-3 countt">
            <span style="text-align: right;"> {{ rowcount12 }}/{{ dataResult11.length }}</span>
        </div>
        <div class="col-lg-1 col-3">
            <div class="card_1" (click)="GetDataAscDesc()">
                <img src="../../../../assets/img/sort.png" class="sort" id="myButton" />
            </div>
        </div>
        <div class="col-lg-2 col-6" *ngIf="LoginDetails?.RoleID && [1, 3, 4, 5].includes(LoginDetails.RoleID)">
            <button class="btn btn-primary" style="float: left;" (click)="downloadAsExcel()">Download</button>
        </div>
        <div class="col-lg-12">
            <br />
        </div>
        <div class="col-lg-12 ">
            <div class=" ">
                <button class="m-1 alphabet-button border cursor " *ngFor="let letter of alphabetLetters" (click)="filterByAlphabet(letter)">
                    {{ letter }}
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-5   col-6 coltab" (click)="getApprovel(true)">
            <!-- Approved Tab -->
            <div class="m-1 alphabet-button border cursor approvede" (click)="toggleApprovedTab()" [ngClass]="{'active': approvedTabSelected}" style="">Approved</div>
        </div>
        <div class="col-lg-5   col-6" (click)="getApprovel(false)">
            <!-- Not Approved Tab -->
            <div class="m-1 alphabet-button border cursor notapprovede" (click)="toggleNotApprovedTab()" [ngClass]="{'active': notApprovedTabSelected}" style="">Not Approved</div>
        </div>
    </div>
    <div class="card1" *ngFor="let item of filteredData">
        <div class="row">
            <div class="col-lg-6 col-7" *ngIf="item.SelforDatabaseReg=='selfreg'">
            </div>
            <div class="col-lg-2 col-5 text-end" style="margin-left:83%">
                <div class="ribbon" *ngIf="item.SelforDatabaseReg=='selfreg'">  Self Registered</div>
            </div>
            <!--<div class="col-lg-6 col-5 text-end  " *ngIf="item.SelforDatabaseReg=='selfreg'">

                <img *ngIf="item.SelforDatabaseReg=='selfreg'" class="self" src="assets/img/images.png" />
            </div>-->
        </div>
        <!--<div class="col-lg-12 image">

        </div>-->
        <div class="row">
            <!-- Display contact image or first letter -->
            <div class="col-lg-1 col-xs-1 col-1" (click)="mydetails(item.ID)">
                <!-- Use an image if available, otherwise show the first letter -->
                <img *ngIf="item.imageSrc" [src]="item.imageSrc" class="contact-image" alt="Contact Image" />
                <div *ngIf="!item.imageSrc" class="contact-letter"><span class="letter">{{ item.Name.charAt(0)}}</span></div>
            </div>
            <div class="col-lg-5 col-xs-6 col-6" style="text-transform:capitalize;" (click)="mydetails(item.ID)">
                <!-- Display contact details -->
                <p class="names" style="text-transform:capitalize;">{{item.Name}} {{item.Surname}}</p>
                <p class="names" style="text-transform:capitalize;">{{item.CityName}}, {{item.Address}}, {{item.DistrictName}}, {{item.State}}</p>
            </div>
            <div class="col-lg-4 col-9" (click)="mydetails(item.ID)">
                <div class="row buttonns">
                    <div class="col-lg-4 col-xs-3 col-xs-4 mlobilechips">
                        <button *ngIf="item.Approval1 === true" class="btns" style="float:right">
                            ASA
                            <i class="fas fa-check-circle" style="color:green;"></i>
                        </button>
                    </div>
                    <div class="col-lg-4 col-xs-3 col-xs-4 mlobilechips">
                        <button *ngIf="item.Approval2 === true" class="btns" style="float:right">
                            MAL
                            <i class="fas fa-check-circle" style="color:green;"></i>
                        </button>
                    </div>
                    <div class="col-lg-4 col-xs-3 col-xs-4 mlobilechips">
                        <button *ngIf="item.Approval3 === true" class="btns" style="float:right">
                            DNK
                            <i class="fas fa-check-circle" style="color:green;"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-xs-1 col-xs-6 col-3 icons">

                <p class="cursor" style="float: right; color: #3498db;" (click)="mydetails(item.ID)">&rarr;&nbsp;&nbsp;</p>
                <i class="fa fa-trash" *ngIf="LoginDetails?.RoleID === 1" (click)="deleteitem(item.ID)" style="color: red; float: right; font-size: 16px; margin-left: 13px; margin-right: 12px;"></i>
                <i *ngIf="item.isVolunteer==true" class="fa fa-user" style="float: right; color: #3498db;" (click)="mydetails(item.ID)"></i>
            </div>

        </div>

        <!--</div>-->
        <!--<div *ngIf="loadingMoreData" class="loading-message">
            <div class="loading-content">
                <i class="fas fa-spinner fa-spin"></i>  Font Awesome spinner icon
                <span style="color:#3498db">Loading more data...</span>
            </div>
        </div>-->
    </div>
</div>
