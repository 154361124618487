import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { generalserverservice } from '../generalserver.service';
import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ViewportScroller } from '@angular/common';
@Component({
    selector: 'app-userdetails',
    templateUrl: './userdetails.component.html',
    styleUrls: ['./userdetails.component.scss'],
})
export class UserdetailsComponent implements OnInit {
    ID: any; ID1: any;
    userData: any;
    LoginDetails: any;
    LoginDetails1: any;
    HomeUrl: any;
    dataResult1: any;
    dataResult: any = [];
    arr: any[];
    filteredData: any;
    filteredDataLength: any;
    showDetails = false;
    Name: any; // Add any other properties you need
    Surname: any;
    mobileno: any;
    Address: any;
    Gender: any;
    state: any;
    city: any;
    Father: any;
    Spouse: any;
    Email: any;
    AlternativeMobile: any;
    Maritalstatus: any;
    Pincode: any;
    Countryid: any;
    CompanyName: any;
    CompanyAddress: any;
    CompanyPincode: any;
    CompanyCity: any;
    CreatedBy: any;
    Status: any;
    CreatedDate: any;
    ModifiedDate: any;
    ModifiedBy: any;
    RoleID: any;
    UserCount: any;
    Age: any;
    Occupation: any;
    Dno: any;
    DistrictID: any;
    Image: any;
    OriginalAddress: any;
    Idproof: any;
    IdProofImage: any;
    StayDuration: any;
    DateOfBirth: any;
    Date: any;
    MembershipNum: any;
    Remarks: any;
    HowMuchPaid: any;
    VolunteerPhNumber: any;
    VolunteerName: any;
    //  PaidOnDate: any;
    AllStatus: any;
    AllTypes: any; Approval1: any; Approval2: any; Approval3: any;
    public form: FormGroup;
    showApprovalMessage: boolean = false;
    showRejectionMessage: boolean = false;
    approvedName: string = '';
    rejectedName: string = '';
    approvalMessage: string = '';
    rejectionMessage: string = '';
    val: any; msg: any;
    arr2: any = [];
    isApproved1: boolean = false;
    isApproved2: boolean = false;
    isApproved3: boolean = false;
    approveClicked: boolean = false;
    flag: any;
    selectedGender: any;
    user: any;
    Membershiptype: any;
    isRole3Enabled: boolean = false;
    isRole4Enabled: boolean = false;
    enabled: boolean = false;
    approvalDone: boolean = false;
    isApproval2Approved: boolean = false;
    isVolunteer: boolean = false;
    Volunteer: any;

    @ViewChild('cardContent') cardContent: ElementRef;
    VoterId: any;
    // @ViewChild('cardContent') cardContent: ElementRef;
    // @ViewChild('cardContent', { static: true }) cardContent!: ElementRef;
    constructor(public router: Router, public generalService: generalserverservice,
        public http: HttpClient, public activeroute: ActivatedRoute,
        public fb: FormBuilder, private viewportScroller: ViewportScroller
    ) {
        debugger
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (this.LoginDetails != null) {
            this.Volunteer = localStorage.getItem("isVolunteer");
        }
        this.ID1 = this.activeroute.snapshot.paramMap.get("dee");
        if (this.ID1 == 1) {
            this.ID = this.LoginDetails.ID;
            this.GetUserDetailedView();
            this.GetUserDetailedViews();

        }
        this.ID = this.activeroute.snapshot.paramMap.get("ID");




        //this.fetchLoginDetails();
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

        this.form = this.fb.group({
            //    Membesrshiptype: ['', Validators.required], // Adjusted form control name
            MembershipNum: [''],
            HowMuchPaid: [''],
            Dateofmemebership: [''],
            Remarks: [''],
            ReceiptSourceNumber: [''],
            ReceiptDate: [''],
            isVolunteer: [''],
            VolunteerName: [''],
            VolunteerPhNumber: [''],
        });
        this.GetUserDetailedViews();
        if (this.LoginDetails.RoleID === 3) {
            this.isRole3Enabled = true;
        } else if (this.LoginDetails.RoleID === 4) {
            this.isRole4Enabled = true;
        } else if (this.LoginDetails.RoleID === 5) {
            this.enabled = true;
        }
    }

    ngOnInit() {
        // this.GetUser();
        this.GetUserDetailedView();
        this.GetStatus();
        this.GetMemberType();
        this.GetUserDetailedViews();


    }

    goto() {
        this.router.navigate(['/']);
    }

    clickonEdit() {
        this.router.navigate(['/Detailsedit', { userdet: JSON.stringify(this.userData[0].ID) }]);
    }
    downloadAsImage() {
        const element = this.cardContent.nativeElement;

        html2canvas(element).then(canvas => {
            const imgData = canvas.toDataURL('image/png');

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'card_image.png'; // Specify the filename here
            link.click();
        }).catch(error => {
            console.error('Error generating image:', error);
        });
    }



    //GetUser() {
    //    this.arr = [];
    //    this.arr.push({});
    //    var UploadFile = new FormData();
    //    UploadFile.append("Param", JSON.stringify(this.arr));
    //    UploadFile.append("Flag", '3');
    //    var url = "api/Events/SearchContactList";
    //    this.generalService.PostData(url, UploadFile).then(data => {
    //        this.dataResult = data;
    //        this.filteredData = this.dataResult;
    //        this.filteredDataLength = this.filteredData.length;
    //    }, err => {
    //        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    //    })
    //}

    OpenImage(Img: any) {
        var Image = this.HomeUrl + Img;
        window.open(Image, '_blank');
    }




    Donersform() {
        debugger;
        this.arr = [];

        // Assuming flag is a property of the component class
        const flag = 1; // Or whatever value you intend to use

        this.arr.push({
            ID: this.userData[0].ID,
            Membershiptype: this.selectedGender,
            MembershipNum: this.form.value.MembershipNum,
            HowMuchPaid: this.form.value.HowMuchPaid,
            Dateofmemebership: this.form.value.Dateofmemebership,
            Remarks: this.form.value.Remarks,
            ReceiptSourceNumber: this.form.value.ReceiptSourceNumber,
            ReceiptDate: this.form.value.ReceiptDate,
            isVolunteer: this.form.value.isVolunteer,
            VolunteerName: this.form.value.VolunteerName,
            VolunteerPhNumber: this.form.value.VolunteerPhNumber
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1'); // Convert flag to string before appending
        var url = "api/Events/Membershipdetails";
        this.generalService.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
                debugger
                if (this.flag == 1) { // Corrected to use this.flag
                    this.generalService.ShowAlert('SUCCESS', 'Membership details submitted successfully', 'success');
                } else {
                    this.generalService.ShowAlert('SUCCESS', 'Membership details submitted successfully', 'success');
                }

            } else {
                // Handle submission failure
                this.generalService.ShowAlert('SUCCESS', 'Membership details submitted successfully', 'success');
                window.location.reload();
            }
        }).catch(error => {
            // Handle errors from the API call
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        });
    }




    GetUserDetailedView() {
        debugger
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
        UploadFile.append("Param", this.ID);
        var url = "api/Events/UserDetailedView";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult = data;
            this.userData = this.dataResult;
            this.Name = this.userData[0].Name;
            this.VoterId = this.dataResult[0].VoterId;
            this.Surname = this.userData[0].Surname;
            this.mobileno = this.userData[0].Mobile;
            this.Address = this.userData[0].Address;
            this.state = this.userData[0].State;
            this.city = this.userData[0].CityName;
            this.Father = this.userData[0].Father;
            this.Spouse = this.userData[0].Spouse;
            this.Gender = this.userData[0].GenderName;
            this.Email = this.userData[0].Email;
            this.AlternativeMobile = this.userData[0].AlternativeMobile;
            this.Maritalstatus = this.userData[0].MartialStatus;
            this.Pincode = this.userData[0].Pincode;
            this.Countryid = this.userData[0].Countryid;
            this.CompanyName = this.userData[0].CompanyName;
            this.CompanyAddress = this.userData[0].CompanyAddress;
            this.CompanyCity = this.userData[0].CompanyCity;
            this.CompanyPincode = this.userData[0].CompanyPincode;
            this.CreatedBy = this.userData[0].CreatedBy;
            this.Status = this.userData[0].Status;
            this.CreatedDate = this.userData[0].CreatedDate;
            this.ModifiedDate = this.userData[0].ModifiedDate;
            this.ModifiedBy = this.userData[0].ModifiedBy;
            this.RoleID = this.userData[0].RoleID;
            this.UserCount = this.userData[0].UserCount;
            this.Age = this.userData[0].Age;
            this.Occupation = this.userData[0].Occupation;
            this.Dno = this.userData[0].Dno;
            this.DistrictID = this.userData[0].DistrictName;
            this.Image = this.userData[0].Image;
            this.OriginalAddress = this.userData[0].OriginalAddress;
            this.Idproof = this.userData[0].Idproof;
            this.IdProofImage = this.userData[0].IdProofImage;
            this.StayDuration = this.userData[0].StayDuration;
            this.DateOfBirth = this.userData[0].DateOfBirth;
            this.Date = this.userData[0].Date;
            this.Membershiptype = this.userData[0].Membershiptype,
                this.MembershipNum = this.userData[0].MembershipNum;
            this.Remarks = this.userData[0].Remarks;
            this.HowMuchPaid = this.userData[0].HowMuchPaid;
            this.VolunteerName = this.userData[0].VolunteerName;
            this.VolunteerPhNumber = this.userData[0].VolunteerPhNumber;
            this.isVolunteer = this.userData[0].isVolunteer;
            this.Approval1 = this.userData[0].Approval1;
            this.Approval2 = this.userData[0].Approval2;
            this.Approval3 = this.userData[0].Approval3;
            this.bindFormData(data);
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }



    bindFormData(data) {
        debugger
        if (data) {
            this.selectedGender = data[0].Membershiptype;
            localStorage.setItem("Membertype", this.selectedGender)
            debugger
            this.form.patchValue({

                MembershipNum: data[0].MembershipNum,
                HowMuchPaid: data[0].HowMuchPaid,
                // isVolunteer: data[0].isVolunteer,
                Dateofmemebership: data[0].Dateofmemebership,
                Remarks: data[0].Remarks,
                ReceiptDate: data[0].ReceiptDate,
                ReceiptSourceNumber: data[0].ReceiptSourceNumber,
                VolunteerName: data[0].VolunteerName,
                VolunteerPhNumber: data[0].VolunteerPhNumber,
                selectedGender: this.selectedGender,


                Messagetorequester: data[0].Messagetorequester
            });
        }
    }
    GetStatus() {
        var url = "api/Events/Get_ECApprovalStatus"
        this.generalService.GetData(url).then(data => {
            this.AllStatus = data;
        })
    }
    GetMemberType() {
        var url = "api/Events/Get_MemberType";
        this.generalService.GetData(url).then(data => {
            this.AllTypes = data;
            // Assuming data is an array of objects with properties 'id' and 'name'
            this.AllTypes.forEach(type => {
                // Create a new div element for each gender type
                const divElement = document.createElement('div');
                divElement.classList.add('col-lg-3', 'col-xs-6');

                // Create a new chip element and configure it
                const chipElement = document.createElement('div');
                chipElement.classList.add('chip1');
                chipElement.textContent = type.name; // Assuming 'name' property holds gender name
                chipElement.addEventListener('click', () => this.selectGender(type.name)); // Assuming 'name' property holds gender name

                // Add ngClass dynamically
                chipElement.classList.toggle('selected', this.selectedGender === type.name);

                // Append chip element to the dynamically created div
                divElement.appendChild(chipElement);

                // Append the dynamically created div to the existing row
                document.querySelector('.row').appendChild(divElement);
            });
        });
    }
    selectGender(gender: string) {
        this.selectedGender = gender;
        // Add logic to handle gender selection as needed
    }

    approve(name: string) {
        // Your approval logic goes here
        // For demonstration purposes, let's just show a message
        this.showApprovalMessage = true;
        this.approvedName = name;
        this.approvalMessage = 'Approval status: Approved';
    }

    reject(name: string) {
        // Your rejection logic goes here
        // For demonstration purposes, let's just show a message
        this.showRejectionMessage = true;
        this.rejectedName = name;
        this.rejectionMessage = 'Rejection status: Rejected';
    }

    Approved1(status: string, approvalField: string, approvalValue: any, text: string) {
        debugger;
        var arr2 = [];
        this.arr2 = [];

        // Set approval value to 'true' when status is 'Approved'
        if (status === "Approved") {
            approvalValue = 'true';
        }

        // Push the UserID, approval field, and value to arr2
        this.arr2.push({
            ID: this.ID,

        });

        // Send data to server and handle response
        var UploadFile = new FormData();
        UploadFile.append('Param1', this.ID);
        UploadFile.append('Param2', approvalField);
        UploadFile.append('Param3', approvalValue);
        this.approvalDone = true;
        var url = "api/Events/Update_Users_Approval";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            if (data != "") {
                // Update isApproved variable based on the approvalField
                switch (approvalField) {
                    case 'Approval1':
                        this.isApproved1 = true;
                        break;
                    case 'Approval2':
                        this.isApproved2 = true;
                        break;
                    case 'Approval3':
                        this.isApproved3 = true;
                        break;
                    default:
                        break;
                }

                // Navigate and show success message
                if (status === "Approved") {
                    this.msg = "Customer Approved Successfully";
                    this.val = "Approved";
                    this.router.navigate(['/Users']);
                }
                this.generalService.ShowAlert('Success', this.msg, 'Success');
                debugger;
                this.ngOnInit();
            }
            if (approvalField === 'Approval2' && status === 'Approved') {
                this.isApproval2Approved = true;
            }
        }, err => {
            // Show error message if request fails
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        });
    }


    GetUserDetailedViews() {
        debugger


        var UploadFile = new FormData();
        UploadFile.append("Param", this.LoginDetails.Mobile);

        var url = "api/Events/Get_Card_userddetails";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult1 = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    //downloadAsPDF() {
    //    debugger
    //    const element = this.cardContent.nativeElement;

    //    html2canvas(element).then(canvas => {
    //        const imgData = canvas.toDataURL('image/png');
    //        const pdf = new jsPDF();

    //        // Add the image to the PDF with correct arguments
    //        pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), canvas.height * (pdf.internal.pageSize.getWidth() / canvas.width));

    //        pdf.save('receipt.pdf');
    //    }).catch(error => {
    //        console.error('Error generating PDF:', error);
    //    });
    //}


}
