<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <div class="card">
                    <!--<img src="../../../../assets/img/login1.PNG" class="mobile_login" />-->
                    <form>
                        <label for="username">Mobile </label>
                        <input type="text" [(ngModel)]="Mobile" placeholder="Enter Your Mobile Number" id="username" name="username" inputmode="numeric" pattern="[0-9]*" maxlength="10" required>
                        <label for="password" *ngIf="OTPtoMobile != null">OTP </label>
                        <input type="password" [(ngModel)]="OTP" id="password" name="password" *ngIf="OTPtoMobile != null" required>
                        <br />
                        <button class="btn btn-primary" type="submit" (click)="SendOTPtoMobile()" [hidden]="!showLogin1" [disabled]="otpSent">Send OTP</button>
                        &nbsp;
                        <button class="btn btn-primary" type="submit" (click)="checkmobile(Mobile)" [hidden]="!showLogin">Login</button>
                    </form>

                </div>
            </div>
            <div class="col-lg-3"></div>

        </div>
        <br />
    </div>
    </div>
