import { Component } from '@angular/core';
import { generalserverservice } from '../generalserver.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/*import { SignaturePadModule } from 'ngx-signature-pad';*/
@Component({
    selector: 'app-detailsedit',
    templateUrl: './detailsedit.component.html',
    styleUrls: ['./detailsedit.component.scss']
})
export class DetailseditComponent {
    Form: FormGroup;
    cols: any;
    HomeUrl: any;
    loginDet: any;
    arr: any = [];
    dataResult: any;
    gridview: boolean = true;
    CM_ID: any;
    Status: any;
    flag: any;
    userdet1: any;
    userdet: any;
    submitAttempt: boolean; 
    userData: any; FName: any; Name: any; Surname: any;
    mobileno: any;
    Address: any;  Gender: any; state: any; city: any; Father: any;
    Spouse: any; Email: any; AlternativeMobile: any;
    Maritalstatus: any; 
    Pincode: any; Countryid: any; CompanyName: any; CompanyAddress: any;
    CompanyCity: any; CreatedBy: any;  CreatedDate: any;
    ModifiedDate: any; ModifiedBy: any;
    RoleID: any; UserCount: any;
    Age: any;
    ID: any;
    Occupation: any;
   /* Dno: any;*/ 
    DistrictID: any;
    statedata: any;
    districtdata: any;
    selectedStateId: any;
    selecteddistrict: any;
    citiesdata: any;
    selectecitys: any;
    surnamedata: any;
    selectedSurname: any;
    imagefile2: any;
    Image: any;
    Genders: any;
    LoginDetails: any;
    membertype: any; VolunteerPhNumber: any; VolunteerName: any;isVolunteer: boolean = false;
    SelectedGender: any;
    Martials: any; imagefile1: any;
    SelectedMartials: any; IdProofImage: any;   
    Idproof: any; DateOfBirth: any; OriginalAddress: any; StayDuration: any; ECAprovalSiganture: any; Signature: any;
    Date: any; MembershipDetails: any; MembershipNum: any; Remarks: any; HowMuchPaid: any;
    PaidOnDate: any; Approval1: any; Approval2: any; Approval3: any; AllTypes: any;
    selected1: Date; ReceiptSourceNumber: any; ReceiptDate: any; Dateofmemebership: any; isVolunteer1: any;
    constructor(public generalService: generalserverservice, public activatedRoute: ActivatedRoute, public router: Router, public fb: FormBuilder, public http: HttpClient)
    {

        this.membertype=localStorage.getItem("Membertype")
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.userdet1 = this.activatedRoute.snapshot.paramMap.get("userdet");
        this.userdet = JSON.parse(this.userdet1);
        this.Form = fb.group({
            Surname: ['', Validators.required],
            Name: ['', Validators.required],
            DateOfBirth: ['', Validators.required],
            Father: ['',],
            OriginalAddress: ['', Validators.required],
            Address: ['', Validators.required],
            Mobile: ['', Validators.required],
            AlternativeMobile: ['', Validators.required],
            Occupation: ['', Validators.required],
            CompanyAddress: ['', Validators.required],
            StayDuration: ['', Validators.required],
            Idproof: ['', Validators.required],  
            Email: ['', Validators.required],
            MembershipDetails: ['', Validators.required],
            Date: ['', Validators.required],
            NativeAddress: ['',], 
            MembershipNum: ['', Validators.required], 
            Image: ['',],
            IdProofImage:['',],
            Remarks: ['', Validators.required], 
            Age: ['',],
            membertype: ['',],
            HowMuchPaid: ['',], PaidOnDate: ['',], Approval1: ['',], Approval2: ['',], Approval3: ['',],
            ReceiptSourceNumber: ['',], ReceiptDate: ['',], Dateofmemebership: ['',],
            VolunteerPhNumber: ['',], VolunteerName: ['',], 
            //Disclaimer: ['', Validators.required],
            isVolunteer: ['', Validators.required],
            
             

        });
        this.Form.patchValue({ Disclaimer: null });

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit() {
        this.Getsurname();
        this.Getstates();
        this.GetGender();
        this.GetMartialStatus();
        this.GetUserDetailedView();
        this.GetProfile();
        this.GetMemberType();
    }
     
    clickonback() {
        this.router.navigate(['/']);
    }

    Getstates() { 

        var url = "api/Events/GetStates_grid";
        this.generalService.GetData(url).then(data => {
            debugger
            this.statedata = data; 
            //this.Getdistricts(); 
            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }



    GetGender() {
        var url = "api/Events/Get_GenderMaster";
        this.generalService.GetData(url).then(data => {
            debugger
            this.Genders = data;
            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    GenderSelection(val: any) {
        debugger
        this.SelectedGender = val;
    }

    GetMartialStatus() {
        var url = "api/Events/Get_FillingStatus";
        this.generalService.GetData(url).then(data => {
            debugger
            this.Martials = data;
            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    MartialStatus(val: any) {
        debugger
        this.SelectedMartials = val;
    }

  

    toggleVolunteer($event) {

        debugger
        if (!$event.target.checked == true) {

            this.Form.patchValue({ Disclaimer: null });
        }
        //else if (!$event.detail.checked == false) {
        //  this.UserForm.patchValue({ Disclaimer: null });
        //}
    }

    Getsurname() { 
        var url = "api/Events/GetSurNames";
        this.generalService.GetData(url).then(data => {
            debugger
            this.surnamedata = data; 

            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    GetMemberType() {
        var url = "api/Events/Get_MemberType"
        this.generalService.GetData(url).then(data => {
            this.AllTypes = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    onSurnameSelection(value: string) {
        this.selectedSurname = value;
        // You can also set the value to your form control here if needed:
        // this.Form.controls['Surname'].setValue(value);
    }
    onStateChange(val: any) {
        debugger
       
        this.selectedStateId = val; // Parse the selected value to number if needed
        // Call the Getdistricts() method when the state changes
        this.Getdistricts(this.selectedStateId);
    }

    onStateChange1(val: any) {
        debugger

        this.selecteddistrict = val; // Parse the selected value to number if needed
        // Call the Getdistricts() method when the state changes
        this.Getcities(this.selecteddistrict);
    }
    onStateChange2(val: any) {
        debugger

        this.selectecitys = val; // Parse the selected value to number if needed
        // Call the Getdistricts() method when the state changes
      
    }
    Getcities(DistrictId: any) {
        debugger
        this.arr = []
        this.arr.push({
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", DistrictId);

        var url = "api/Events/GetCitiesgrid";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.citiesdata = data; 


        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    Getdistricts(StateId: any) {
        debugger
        this.arr = []
        this.arr.push({
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", StateId);

        var url = "api/Events/GetDistricts_grid";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.districtdata = data; 

           
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
   
    GetUserDetailedView() {
        debugger
        this.arr = []
        this.arr.push({
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", this.userdet);

        var url = "api/Events/UserDetailedView";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult = data;
            debugger
            this.userdet = this.dataResult; 

            this.Form.controls['Surname'].setValue(this.userdet[0].Surname);
            this.Form.controls['Name'].setValue(this.userdet[0].Name);
            this.Form.controls['Age'].setValue(this.userdet[0].Age);
            this.Form.controls['Father'].setValue(this.userdet[0].Father);
            this.Form.controls['OriginalAddress'].setValue(this.userdet[0].OriginalAddress);
            this.Form.controls['Address'].setValue(this.userdet[0].Address);
            this.Form.controls['Mobile'].setValue(this.userdet[0].Mobile); 
            this.Form.controls['AlternativeMobile'].setValue(this.userdet[0].AlternativeMobile);
            this.Form.controls['Occupation'].setValue(this.userdet[0].Occupation);
            this.Form.controls['CompanyAddress'].setValue(this.userdet[0].CompanyAddress);
            this.Form.controls['StayDuration'].setValue(this.userdet[0].StayDuration);
            this.Form.controls['Idproof'].setValue(this.userdet[0].Idproof); 
            this.Form.controls['Email'].setValue(this.userdet[0].Email); 
            this.Form.controls['DateOfBirth'].setValue(this.userdet[0].DateOfBirth);
            this.Form.controls['MembershipDetails'].setValue(this.userdet[0].MembershipDetails);
            this.Form.controls['ReceiptSourceNumber'].setValue(this.userdet[0].ReceiptSourceNumber);
            this.Form.controls['ReceiptDate'].setValue(this.userdet[0].ReceiptDate);
            this.Form.controls['HowMuchPaid'].setValue(this.userdet[0].HowMuchPaid);
            this.Form.controls['MembershipNum'].setValue(this.userdet[0].MembershipNum);
            this.Form.controls['Dateofmemebership'].setValue(this.userdet[0].Dateofmemebership);
           // this.Form.controls['isVolunteer'].setValue(this.userdet[0].isVolunteer); 
            this.Form.controls['VolunteerPhNumber'].setValue(this.userdet[0].VolunteerPhNumber);
            this.Form.controls['VolunteerName'].setValue(this.userdet[0].VolunteerName);
            this.Form.controls['Remarks'].setValue(this.userdet[0].Remarks);
            this.IdProofImage = this.userdet[0].IdProofImage;
            this.Image = this.userdet[0].Image;  
            this.selectedStateId = this.userdet[0].StateID;  
            this.Form.controls['Approval1'].setValue(this.userdet[0].Approval1);
            this.Form.controls['Approval2'].setValue(this.userdet[0].Approval2);
            this.Form.controls['Approval3'].setValue(this.userdet[0].Approval3); 
             // this.Form.controls['Signature'].setValue(this.userdet[0].Signature);
             // this.Form.controls['ECAprovalSiganture'].setValue(this.userdet[0].ECAprovalSiganture);
            var UploadFile = new FormData();
            UploadFile.append("Param", this.userdet[0].StateID);
            var url = "api/Events/GetDistricts_grid";
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger
                this.districtdata = data;
                var districts = this.districtdata.filter((s: any) => s.DistrictID == this.userdet[0].DistrictID);
                this.Form.controls['district'].setValue(this.userdet[0].DistrictID);
                this.selecteddistrict = this.userdet[0].DistrictID;
                var UploadFile = new FormData();
                UploadFile.append("Param", this.userdet[0].DistrictID);
                var url = "api/Events/GetCitiesgrid";
                this.generalService.PostData(url, UploadFile).then(data => {
                    debugger
                    this.citiesdata = data;
                    var cities = this.citiesdata.filter((s: any) => s.CityId == this.userdet[0].City);
                    this.Form.controls['City'].setValue(this.userdet[0].City);
                    this.selectecitys = this.userdet[0].City;
                })
            })
            this.Image = this.userdet[0].Image;
            this.selectedSurname = this.userdet[0].Surname;
            this.isVolunteer1=this.userdet[0].isVolunteer;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }


    GetProfile() {
        debugger

        this.Form.controls['Surname'].setValue(this.userdet[0].Surname);
        this.Form.controls['Name'].setValue(this.userdet[0].Name);
        this.Form.controls['Age'].setValue(this.userdet[0].Age);
        this.Form.controls['Father'].setValue(this.userdet[0].Father);
        this.Form.controls['OriginalAddress'].setValue(this.userdet[0].OriginalAddress);
        this.Form.controls['Address'].setValue(this.userdet[0].Address);
        this.Form.controls['Mobile'].setValue(this.userdet[0].Mobile);
        this.Form.controls['AlternativeMobile'].setValue(this.userdet[0].AlternativeMobile);
        this.Form.controls['Occupation'].setValue(this.userdet[0].Occupation);
        this.Form.controls['CompanyAddress'].setValue(this.userdet[0].CompanyAddress);
        this.Form.controls['StayDuration'].setValue(this.userdet[0].StayDuration);
        this.Form.controls['Idproof'].setValue(this.userdet[0].Idproof);
        this.Form.controls['Email'].setValue(this.userdet[0].Email);
        this.Form.controls['MembershipDetails'].setValue(this.userdet[0].MembershipDetails);
        this.Form.controls['Date'].setValue(this.userdet[0].Date);
        this.Form.controls['Dateofmemebership'].setValue(this.userdet[0].Dateofmemebership);
       // this.Form.controls['isVolunteer'].setValue(this.userdet[0].isVolunteer);
        this.Form.controls['VolunteerPhNumber'].setValue(this.userdet[0].VolunteerPhNumber);
        this.Form.controls['VolunteerName'].setValue(this.userdet[0].VolunteerName);  
        this.Form.controls['Signature'].setValue(this.userdet[0].Signature);
        this.Form.controls['MembershipNum'].setValue(this.userdet[0].MembershipNum);
        this.Form.controls['ECAprovalSiganture'].setValue(this.userdet[0].ECAprovalSiganture);  
        this.Form.controls['Remarks'].setValue(this.userdet[0].Remarks);
        this.Form.controls['IdProofImage'].setValue(this.userdet[0].IdProofImage);
        this.Form.controls['DateOfBirth'].setValue(this.userdet[0].DateOfBirth);
        this.Form.controls['Image'].setValue(this.userdet[0].Image);
        this.Form.controls['HowMuchPaid'].setValue(this.userdet[0].HowMuchPaid);
        this.Form.controls['PaidOnDate'].setValue(this.userdet[0].PaidOnDate);
        this.Form.controls['Approval1'].setValue(this.userdet[0].Approval1);
        this.Form.controls['Approval2'].setValue(this.userdet[0].Approval2);
        this.Form.controls['Approval3'].setValue(this.userdet[0].Approval3);
        this.Form.controls['isVolunteer'].setValue(this.isVolunteer1);
        this.IdProofImage = this.userdet[0].IdProofImage
    }


    onSubmit(value: any) {
        debugger;
        this.submitAttempt = true;
       /* if (this.Form.valid) {*/
            debugger
           
            this.arr = [];
            this.arr.push({
               //ID: this.ID, 
                ID: this.userdet[0].ID, 
                Surname: this.Form.value.Surname,
                Name: this.Form.value.Name,
                DateOfBirth: this.Form.value.DateOfBirth, 
                Father: this.Form.value.Father,
                OriginalAddress: this.Form.value.OriginalAddress,
                Address: this.Form.value.Address,
                Mobile: this.Form.value.Mobile,
                Age: this.Form.value.Age,
                AlternativeMobile: this.Form.value.AlternativeMobile,
                Occupation: this.Form.value.Occupation, 
                CompanyAddress: this.Form.value.CompanyAddress,
                StayDuration: this.Form.value.StayDuration,
                Idproof: this.Form.value.Idproof,
                Email: this.Form.value.Email,
                MembershipDetails: this.Form.value.MembershipDetails,
                NativeAddress: this.Form.value.NativeAddress,
                Date: this.Form.value.Date,
                isVolunteer: this.Form.value.isVolunteer,
                VolunteerPhNumber: this.Form.value.VolunteerPhNumber,
                VolunteerName: this.Form.value.VolunteerName,
                MembershipNum: this.Form.value.MembershipNum,
                Remarks: this.Form.value.Remarks,
                Image: this.Image,
                IdProofImage: this.IdProofImage,
                HowMuchPaid: this.Form.value.HowMuchPaid,
                PaidOnDate: this.Form.value.PaidOnDate,
                Approval1: this.Form.value.Approval1,
                Approval2: this.Form.value.Approval2,
                Approval3: this.Form.value.Approval3, 

            })
            debugger
            let formData: FormData = new FormData();
            formData.append('Param', JSON.stringify(this.arr));
            formData.append('Flag', '2');
            var url = "api/Events/SearchContactList"

            this.generalService.PostData(url, formData).then((data: any) => {
                if (data == 'Success') {
                    this.generalService.ShowAlert('Success', 'Your Profile Updated successfully.', 'success')
                    debugger
                    this.router.navigate(['/Userdetails', { ID: this.userdet[0].ID}]);
                    //    .then(() => {
                    //        window.location.reload();
                    //    });
                    //this.router.navigate(['/EditProfile', { Mobile: this.form.value.Mobile }]);
                    // this.navCtrl.pop();
                }


            });
        //}
    }
    selected(value: any) {
        this.selected1=value;
    }

   

    //detectFiles2(event) {
    //    const input2 = event.target;
    //    debugger
    //    const reader = new FileReader();
    //    if (input2.files.length) {
    //        debugger
    //        const file = input2.files[0];
    //        reader.onload = () => {
    //            debugger
    //            this.imagefile2 = reader.result;
    //            this.Image = this.imagefile2;
    //        }

    //        reader.readAsDataURL(file);
    //        var selectedFile = file;
    //        var idxDot = selectedFile.name.lastIndexOf(".") + 1;
    //        var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
    //        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "gif" || extFile == "webp") {
    //            debugger
    //            var UploadFile = new FormData();
    //            UploadFile.append("UserImages", file);
    //            var url = this.HomeUrl + "api/Events/AddUserImage";
    //            this.http.post(url, UploadFile).subscribe(data => {
    //                if (data != null) {
    //                    debugger
    //                    this.imagefile2 = this.HomeUrl + data;
    //                    this.Image = data;
    //                }
    //            });
    //        }
    //        else {
    //            alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
    //        }
    //    }


    //}


     detectFiles2(event) {
        const input2 = event.target;
        const reader = new FileReader();

        if (input2.files.length) {
            const file = input2.files[0];
            const ext = file.name.split('.').pop().toLowerCase();
            const allowedExt = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

            if (allowedExt.includes(ext)) {
                reader.onload = () => {
                    // Convert image file to base64 string
                    const base64String = reader.result as string;

                    // Now you can use the base64 string for displaying the image or inserting it into the database
                    this.Image = base64String;

                    // Insert the base64 string into the database
                    this.insertImageToDatabase(base64String);
                };

                reader.readAsDataURL(file);
            } else {
                alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
            }
        }
    }

    insertImageToDatabase(base64String: string) {
        const UploadFile = new FormData();
        UploadFile.append("UserImageBase64", base64String); // Assuming your API expects a key named "UserImageBase64"

        const url = this.HomeUrl + "api/Events/AddUserImageBase64";
        this.http.post(url, UploadFile).subscribe(data => {
            if (data != null) {
                // Handle the response from the server if needed
            }
        });
    }


    detectFiles1(event) {
        const input2 = event.target;
        debugger
        const reader = new FileReader();
        if (input2.files.length) {
            debugger
            const file = input2.files[0];
            reader.onload = () => {
                debugger
                this.imagefile1 = reader.result;
                this.IdProofImage = this.imagefile1;
            }

            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "gif" || extFile == "webp") {
                debugger
                var UploadFile = new FormData();
                UploadFile.append("UserImages", file);
                var url = this.HomeUrl + "api/Events/AddUserImage";
                this.http.post(url, UploadFile).subscribe(data => {
                    if (data != null) {
                        debugger
                        this.imagefile1 = this.HomeUrl + data;
                        this.IdProofImage = data;
                    }
                });
            }
            else {
                alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
            }
        }


    }


    }

