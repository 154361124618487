import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { EmailValidators } from 'ngx-validators'
import { generalserverservice } from '../../components/generalserver.service';
//import { CookieService } from 'ngx-cookie-service';

import Swal from 'sweetalert2';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    HomeUrl: any;
    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    
    LoginDetails: any;
    LoginDetails1: any;
    constructor(public router: Router, public generalService: generalserverservice, public http: HttpClient, public activeroute: ActivatedRoute) {  //

      
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    SendOTPtoMobile() {
        debugger
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/Events/SendOtpToMobile";
            this.generalService.PostData(url, Uploadfile).then((data: any) => {
               


                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
            })
        }
    }

    checkmobile(Mobile) {
        debugger
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);


            var url = 'api/Events/checking_Mobile'

            this.generalService.PostData(url, UploadFile).then(data => {
               
                debugger
                if (data != 'NOTEXIST') {
                    debugger
                    this.pwd1 = data[0].Password;
                    this.userid = data[0].ID;
                    this.router.navigate(['/']).then(() => { window.location.reload(); });
                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    if (this.logindata != null) {
                        this.LoginDetails1 = localStorage.getItem("LoginDetails");
                        this.LoginDetails = JSON.parse(this.LoginDetails1);
                        localStorage.setItem("isVolunteer", this.LoginDetails.isVolunteer);
                    }
                    
                    window.location.reload();
                }
                else {
                  
                    this.router.navigate(['/Registration'])
                        // this.router.navigate(['/register', { Mobile: this.form.value.Mobile }])
                        //.then(() => {
                        //    window.location.reload();
                        //});
                }
            })
            }
        }
    }

}
