<footer class="footer-area">
    <div class="container">
        <!--<div style="margin-top: 9px;">
        <h3><a routerLink="/"><span style="text-transform:capitalize;">Gavara Contacts</span></a></h3>
        <p>Gavara Contacts is powered by <a href="https://gagri.net/" target="_blank"><b>Yellapu Jagan Mohan</b>,</a> Hyderabad</p>

        </div>-->
        <div class="row">
            <h4><a routerLink="/"><span class="gavarafooter" style="">Gavara Contacts</span></a></h4>
            <p>Gavara Contacts is powered by <a href="https://gagri.net/" target="_blank"><b>Yellapu Jagan Mohan</b>,</a> Hyderabad</p>

        </div>
    </div>
</footer>

