import { Component } from '@angular/core';
import { generalserverservice } from '../generalserver.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-newprofiledetails',
    templateUrl: './newprofiledetails.component.html',
    styleUrls: ['./newprofiledetails.component.scss']
})
export class NewprofiledetailsComponent {
    Form: FormGroup;
    cols: any;
    HomeUrl: any;
    loginDet: any;
    arr: any = [];
    dataResult: any;
    gridview: boolean = true;
    CM_ID: any;
    Status: any;
    flag: any;
    userdet1: any;
    userdet: any;
    submitAttempt: boolean;
    userData: any; FName: any; Name: any; Surname: any;
    mobileno: any;
    address: any;
    Gender: any;
    state: any;
    city: any;
    Father: any;
    Spouse: any;
    Email: any;
    AlternativeMobile: any;
    Maritalstatus: any;
    Idproof: any;
    Pincode: any; Countryid: any; CompanyName: any; CompanyAddress: any;
    CompanyCity: any; CreatedBy: any; CreatedDate: any;
    ModifiedDate: any; ModifiedBy: any;
    RoleID: any; UserCount: any;
    Aadhar: any; Age: any;
    ID: any;
    Occupation: any;
    Dno: any;
    DistrictID: any;
    statedata: any;
    Address: any;
    districtdata: any;
    selectedStateId: any;
    selecteddistrict: any;
    citiesdata: any;
    selectecitys: any;
    imagefile2: any;
    Image: any;
    surnamedata: any;
    selectedSurname: any;
    genderdata: any;
    Statusdata: any;
    buttonClicked: boolean = false;
    LoginDetails1: any;
    LoginDetails: any;
    OriginalAddress: any;
    IdProofImage: any; StayDuration: any;
    logindata: any;
    imagefile1: any;
    userdets: string;
    UserDetails: any;
  //  Idproof: any;
   // CompanyCity: any; CompanyAddress: any;
    constructor(public generalService: generalserverservice, private route: ActivatedRoute, private router: Router,
        public fb: FormBuilder, public http: HttpClient) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        this.userdet1 = this.route.snapshot.paramMap.get("userdet");  // Use this.route instead of this.ActivatedRoute
        this.userdet = JSON.parse(this.userdet1);

        //this.userdets = this.route.snapshot.paramMap.get("UserDetails");  // Use this.route instead of this.ActivatedRoute
        //this.UserDetails = JSON.parse(this.userdets);
        //if (this.UserDetails != undefined) {
        //    this.getdata()
        //}

        this.Form = fb.group({

            Surname: ['', Validators.required],
            Name: ['', Validators.required],
            DateOfBirth: ['',],
            Father: ['', Validators.required],
            OriginalAddress: ['', Validators.required],
            Address: ['', Validators.required],
            Mobile: ['', Validators.required],
            AlternativeMobile: ['',],
           // CompanyAddress: ['',],
            Email: ['', Validators.required],
            Idproof: ['',],
            Image: ['', Validators.required],
            //Occupation: ['', Validators.required],
            // GenderName: ['', ],
            MaritalStatus: ['', Validators.required],
            //  Spouse: ['',],
            Age: ['', Validators.required],
            State: ['', Validators.required],
            City: ['', Validators.required],
            district: ['', Validators.required],
            Pincode: ['', Validators.required],
            //CompanyName: ['',],
            //CompanyCity: ['',],
            IdProofImage: ['', Validators.required],
           // StayDuration: ['',],
            // CompanyPincode: ['',],
            //StateID: ['',],

        });

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit() {
        this.GetUserDetailedView();
        this.Getstates();
        this.Getgender();
        this.GetMarital();
        this.Getsurname();
    }


    //getdata() {
    //    debugger
    //    if (this.LoginDetails == undefined) {
    //        this.Form.controls['Surname'].setValue(this.UserDetails.Surname);
    //        this.Form.controls['Name'].setValue(this.UserDetails.Name);
    //        this.Form.controls['Mobile'].setValue(this.UserDetails.Mobile);
    //    }
      
    //}
    Getgender() {


        var url = "api/Events/Get_GenderMaster";
        this.generalService.GetData(url).then(data => {
            debugger
            this.genderdata = data;

            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    GetMarital() {

        var url = "api/Events/Get_FillingStatus";
        this.generalService.GetData(url).then(data => {
            debugger
            this.Statusdata = data;

            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }


    Getsurname() {

        var url = "api/Events/GetSurNames";
        this.generalService.GetData(url).then(data => {
            debugger
            this.surnamedata = data;
            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    onSurnameSelection(value: string) {
        this.selectedSurname = value;
        // You can also set the value to your form control here if needed:
        // this.Form.controls['Surname'].setValue(value);
    }

    Getstates() {


        var url = "api/Events/GetStates_grid";
        this.generalService.GetData(url).then(data => {
            debugger
            this.statedata = data;
            this.Getdistricts();

            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    onStateChange(val: any) {
        debugger

        this.selectedStateId = val; // Parse the selected value to number if needed
        // Call the Getdistricts() method when the state changes
        this.Getdistricts();
    }

    onStateChange1(val: any) {
        debugger

        this.selecteddistrict = val; // Parse the selected value to number if needed
        // Call the Getdistricts() method when the state changes
        this.Getcities();
    }
    onStateChange2(val: any) {
        debugger

        this.selectecitys = val; // Parse the selected value to number if needed
        // Call the Getdistricts() method when the state changes

    }
    Getcities() {
        debugger
        this.arr = []
        this.arr.push({
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", this.selecteddistrict);

        var url = "api/Events/GetCitiesgrid";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.citiesdata = data;

        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    Getdistricts() {
        debugger
        this.arr = []
        this.arr.push({
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", this.selectedStateId);

        var url = "api/Events/GetDistricts_grid";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.districtdata = data


        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    GetUserDetailedView() {
        debugger
        if (this.LoginDetails != undefined) {

        }
        this.arr = []
        this.arr.push({
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", this.LoginDetails[0].Mobile);

        var url = "api/Events/UserDetailedView";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult = data;
            debugger
            this.userdet = this.dataResult;


            this.Form.controls['Surname'].setValue(this.LoginDetails[0].Surname);
            this.Form.controls['Name'].setValue(this.LoginDetails[0].Name);
            this.Form.controls['Mobile'].setValue(this.LoginDetails[0].Mobile);
            this.Form.controls['Father'].setValue(this.LoginDetails[0].Father);
            this.Form.controls['AlternativeMobile'].setValue(this.LoginDetails[0].AlternativeMobile);
            this.Form.controls['Email'].setValue(this.LoginDetails[0].Email);
            this.Form.controls['Occupation'].setValue(this.LoginDetails[0].Occupation);
            this.Form.controls['Gender'].setValue(this.LoginDetails[0].Gender);
            this.Form.controls['Maritalstatus'].setValue(this.LoginDetails[0].Maritalstatus);
            this.Form.controls['DateOfBirth'].setValue(this.LoginDetails[0].DateOfBirth);
            this.Form.controls['Spouse'].setValue(this.LoginDetails[0].Spouse);
            this.Form.controls['Age'].setValue(this.LoginDetails[0].Age);
            this.Form.controls['Address'].setValue(this.LoginDetails[0].Address);
            this.Form.controls['State'].setValue(this.LoginDetails[0].State);
            this.Form.controls['City'].setValue(this.LoginDetails[0].City);
            this.Form.controls['Pincode'].setValue(this.LoginDetails[0].Pincode);
            this.Form.controls['CompanyName'].setValue(this.LoginDetails[0].CompanyName);
            this.Form.controls['CompanyAddress'].setValue(this.LoginDetails[0].CompanyAddress);
            this.Form.controls['CompanyCity'].setValue(this.LoginDetails[0].CompanyCity);
            this.Form.controls['OriginalAddress'].setValue(this.LoginDetails[0].OriginalAddress);
            this.Form.controls['Idproof'].setValue(this.LoginDetails[0].Idproof);
            this.Form.controls['IdProofImage'].setValue(this.LoginDetails[0].IdProofImage);
            this.Form.controls['Image'].setValue(this.LoginDetails[0].Image);
            this.Form.controls['StayDuration'].setValue(this.LoginDetails[0].StayDuration);


        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    GetProfile() {
        debugger

        this.Form.controls['Surname'].setValue(this.LoginDetails[0].Surname);
        this.Form.controls['Name'].setValue(this.LoginDetails[0].Name);
        this.Form.controls['Mobile'].setValue(this.LoginDetails[0].Mobile);
        this.Form.controls['Father'].setValue(this.LoginDetails[0].Father);
        this.Form.controls['AlternativeMobile'].setValue(this.LoginDetails[0].AlternativeMobile);
        this.Form.controls['Email'].setValue(this.LoginDetails[0].Email);
        this.Form.controls['Occupation'].setValue(this.LoginDetails[0].Occupation);
        this.Form.controls['Gender'].setValue(this.LoginDetails[0].Gender);
        this.Form.controls['Maritalstatus'].setValue(this.LoginDetails[0].Maritalstatus);
        this.Form.controls['DateOfBirth'].setValue(this.LoginDetails[0].DateOfBirth);
        this.Form.controls['Spouse'].setValue(this.LoginDetails[0].Spouse);
        this.Form.controls['Age'].setValue(this.LoginDetails[0].Age);
        this.Form.controls['Address'].setValue(this.LoginDetails[0].Address);
        this.Form.controls['State'].setValue(this.LoginDetails[0].State);
        this.Form.controls['City'].setValue(this.LoginDetails[0].City);
        this.Form.controls['Pincode'].setValue(this.LoginDetails[0].Pincode);
        this.Form.controls['CompanyName'].setValue(this.LoginDetails[0].CompanyName);
        this.Form.controls['CompanyAddress'].setValue(this.LoginDetails[0].CompanyAddress);
        this.Form.controls['CompanyCity'].setValue(this.LoginDetails[0].CompanyCity);
        this.Form.controls['OriginalAddress'].setValue(this.LoginDetails[0].OriginalAddress);
        this.Form.controls['Idproof'].setValue(this.LoginDetails[0].Idproof);
        this.Form.controls['IdProofImage'].setValue(this.LoginDetails[0].IdProofImage);
        this.Form.controls['Image'].setValue(this.LoginDetails[0].Image);
        this.Form.controls['StayDuration'].setValue(this.LoginDetails[0].StayDuration);
    }


    onSubmit(value: any) {
        debugger;
        this.submitAttempt = true;
        var arr: []

        if (this.Form.valid && this.LoginDetails && this.LoginDetails.length > 0) {
            this.arr = [{
                
                ID: this.LoginDetails[0].ID ,
                Surname: this.LoginDetails[0].Surname,
                Name: this.LoginDetails[0].Name,
                Mobile: this.LoginDetails[0].Mobile,
                Father: this.Form.value.Father,
                AlternativeMobile: this.Form.value.AlternativeMobile,
                Email: this.Form.value.Email,
                Occupation: this.Occupation,
                Maritalstatus: this.Form.value.MaritalStatus,
                DateOfBirth: this.Form.value.DateOfBirth,
                Age: this.Form.value.Age,
                Spouse: this.Form.value.Spouse, //10
                Gender: this.Form.value.GenderName,
                Address: this.Form.value.Address,
                State: this.selectedStateId,
                DistrictID: this.selecteddistrict,
                City: this.selectecitys,
                Pincode: this.Form.value.Pincode,
                CompanyName: this.CompanyName,
                CompanyAddress: this.CompanyAddress,
                CompanyCity: this.CompanyCity,
                OriginalAddress: this.Form.value.OriginalAddress,
                Idproof: this.Form.value.Idproof,
                IdProofImage: this.IdProofImage,
                Image: this.Image,
                StayDuration: this.StayDuration,
               // Image: this.Form.value.Image
                SelforDatabaseReg: 'selfreg'
                
            }];

            let formData: FormData = new FormData();
            formData.append('Param', JSON.stringify(this.arr));
            formData.append('Flag', '2');

            var url = "api/Events/SearchContactList";

            this.generalService.PostData(url, formData).then((data1: any) => {
                debugger
                if (data1 !== null) {
                    var UploadFile = new FormData();
                    UploadFile.append("Mobile", this.LoginDetails[0].Mobile);


                    var url = 'api/Events/checking_Mobile'

                    this.generalService.PostData(url, UploadFile).then(data => {

                        debugger
                        if (data != 'NOTEXIST') {
                            debugger

                          //  this.router.navigate(['/']).then(() => { window.location.reload(); });
                            this.logindata = JSON.stringify(data[0]);
                            localStorage.setItem("LoginDetails", this.logindata);
                            this.generalService.ShowAlert('Success', 'Your registration completed successfully.', 'success');
                            this.router.navigate(['/Userdetails', {  dee:1 }]);
                           // window.location.reload();
                        }
                        else {
                            this.router.navigate(['/Registration'])
                            //this.router.navigate(['/Registration'])
                            // this.router.navigate(['/register', { Mobile: this.form.value.Mobile }])
                            //.then(() => {
                            //    window.location.reload();
                            //});
                        }
                    })

                } else {
                    // Handle other cases or show an error message
                }
            }).catch(error => {
                console.error('Error updating profile:', error);
                // Handle the error, show an alert, or log it
            });
        } else {

            this.generalService.ShowAlert('Warning','Please Enter above Fields','Warning')
            // Handle the case where the form is not valid or userdet is not available
        }
    }

    detectFiles2(event) {
        const input2 = event.target;
        const reader = new FileReader();

        if (input2.files.length) {
            const file = input2.files[0];
            const ext = file.name.split('.').pop().toLowerCase();
            const allowedExt = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

            if (allowedExt.includes(ext)) {
                reader.onload = () => {
                    // Convert image file to base64 string
                    const base64String = reader.result as string;

                    // Now you can use the base64 string for displaying the image or inserting it into the database
                    this.Image = base64String;

                    // Insert the base64 string into the database
                    this.insertImageToDatabase(base64String);
                };

                reader.readAsDataURL(file);
            } else {
                alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
            }
        }
    }

    insertImageToDatabase(base64String: string) {
        const UploadFile = new FormData();
        UploadFile.append("UserImageBase64", base64String); // Assuming your API expects a key named "UserImageBase64"

        const url = this.HomeUrl + "api/Events/AddUserImageBase64";
        this.http.post(url, UploadFile).subscribe(data => {
            if (data != null) {
                // Handle the response from the server if needed
            }
        });
    }


    detectFiles1(event) {
        const input2 = event.target;
        debugger
        const reader = new FileReader();
        if (input2.files.length) {
            debugger
            const file = input2.files[0];
            reader.onload = () => {
                debugger
                this.imagefile1 = reader.result;
                this.IdProofImage = this.imagefile1;
            }

            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "gif" || extFile == "webp") {
                debugger
                var UploadFile = new FormData();
                UploadFile.append("UserImages", file);
                var url = this.HomeUrl + "api/Events/AddUserImage";
                this.http.post(url, UploadFile).subscribe(data => {
                    if (data != null) {
                        debugger
                        this.imagefile1 = this.HomeUrl + data;
                        this.IdProofImage = data;
                    }
                });
            }
            else {
                alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
            }
        }


    }


     
}

