<div class="container mt-5">
    <div class="card">
        <!--<div class="row">
            <div class="col-lg-9">
            </div>
            <div class="col-lg-3 text-end col-xs-6"  *ngIf="ID==LoginDetails.ID">
                <button class="btn btn-primary" (click)="clickonback()" style="float:left">back</button>
            </div>
        </div>-->
        <div id="clickonEdit">

            <form>
                <fieldset [formGroup]="Form">
                    <legend>
                        <p class="legend mgt-5">&nbsp;Personal Details</p>
                    </legend>

                    <div class="row" *ngIf="loginDet?.RoleID === 1">
                        <div class="col-lg-3"><label class="labell"><b>Is a Volunteer?</b></label></div>
                        <div class="col-lg-2 col-xs-6"><input type="checkbox" formControlName="isVolunteer" (change)="toggleVolunteer($event)" /></div>
                    </div>

                    <!--<div *ngIf="loginDet?.RoleID === 1" style="margin-left:25px">
                     <span><b>Is a Volunteer?</b></span>&nbsp;
                      <input type="checkbox" [(ngModel)]="isVolunteer" />
                    </div>-->


                    <div class="row">
                        <div class="col-lg-3"><label><b> Surname<span style="color:red">*</span></b></label></div>
                        <div class="col-lg-4">
                            <select class="form-control" formControlName="Surname" (change)="onSurnameSelection($event.target.value)">
                                <option value="">Select Surname</option>
                                <option *ngFor="let surname of surnamedata" [value]="surname.Surname">{{ surname.Surname }}</option>
                            </select>
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Surname.touched && Form.controls.Surname.errors?.required">Surname is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3"><label><b>Full Name<span style="color:red">*</span></b></label></div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Name" type="text" placeholder="Name ">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Name.touched && Form.controls.Name.errors?.required">Name is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">  <label><b>Date of Birth<span style="color:red">*</span></b></label></div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="DateOfBirth" type="date" name="date" placeholder="Date Of Birth">
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b> Age<span style="color:red">*</span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Age" type="text" placeholder="Age">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Age.touched && Form.controls.Age.errors?.required">Age is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b> Fathers Name<span style="color:red">*</span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Father" type="text" placeholder="Fathers Name ">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Father.touched && Form.controls.Father.errors?.required">Fathers Name is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Current Address <span style="color:red">*</span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Address" type="text" placeholder="Present Address">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Address.touched && Form.controls.Address.errors?.required">Address is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Native Address<span style="color:red">*</span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="OriginalAddress" type="text" placeholder="Native Address">
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b> Mobile Number <span style="color:red">*</span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Mobile" type="text" inputmode="numeric" pattern="[0-9]*" maxlength="10" placeholder="Mobile Number ">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Mobile.touched && Form.controls.Mobile.errors?.required">Mobile Number is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b> Alternate Mobile</b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="AlternativeMobile" type="text" inputmode="numeric" pattern="[0-9]*" maxlength="10" placeholder="Alternative Mobile ">
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Occupation/Employment</b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Occupation" type="text" placeholder="Occupation/Empoyment">
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Company Name <span style="color:red"></span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="CompanyAddress" type="text" placeholder="Company Name">
                            <!--  <small style="color:red" class="text-danger" *ngIf="Form.controls.CompanyAddress.touched && Form.controls.CompanyAddress.errors?.required">CompanyAddress is required</small>-->
                        </div>
                        <div class="col-lg-5"></div>
                        <div class="col-lg-3">
                            <label><b>Since How Long Staying Here</b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="StayDuration" type="text" placeholder="Enter How Long Staying Here">
                        </div>
                        <div class="col-lg-5"></div>
                        <div class="col-lg-3">
                            <label><b> Email ID <span style="color:red">*</span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Email" type="text" placeholder="Email ">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Email.touched && Form.controls.Email.errors?.required">Email is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Adhar number<span style="color:red">*</span></b></label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" formControlName="Idproof" type="text" placeholder="Any ID Proof Number">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Idproof.touched && Form.controls.Idproof.errors?.required">Idproof is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="row">
                            <div class="col-lg-4 mt-1">
                                <p> <b style="color:red;">Note:</b> &nbsp;Please upload only JPG & PNG image</p>
                                <div class="form-group">
                                    <label><b>Id Proof Image</b></label>
                                    <div class="images-container">
                                        <div class="m-image-wrapper">
                                            <img [src]="HomeUrl+IdProofImage" *ngIf="IdProofImage" style="cursor: pointer; height: 180px; width: 17rem;">
                                        </div>
                                    </div>
                                    <br />
                                    <input formControlName="IdProofImage" type="file" (change)="detectFiles1($event)" class="m-img-upload-btn" accept="image/*" />

                                    <small style="color: red" class="text-danger" *ngIf="Form.controls.IdProofImage.touched && Form.controls.IdProofImage.errors?.required">Image is required</small>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-1">
                                <p> <b style="color:red;">Note:</b> &nbsp;Please upload only JPG & PNG image</p>
                                <div class="form-group">
                                    <label><b>Profile IMG</b></label>
                                    <div class="images-container">
                                        <div class="m-image-wrapper">
                                            <img [src]="Image" *ngIf="Image" style="cursor: pointer; height: 180px; width: 17rem;">
                                        </div>
                                    </div>
                                    <br />
                                    <input formControlName="Image" type="file" (change)="detectFiles2($event)" class="m-img-upload-btn" accept="image/*" />

                                    <small style="color: red" class="text-danger" *ngIf="Form.controls.Image.touched && Form.controls.Image.errors?.required">Image is required</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                &nbsp;
                <fieldset [formGroup]="Form">
                    <!--*ngIf="LoginDetails?.RoleID === 2"-->
                    <legend>
                        <p class="legend mgt-5">&nbsp;Membership Details</p>
                    </legend>
                    <div class="row">
                        <div class="col-lg-3 col-xs-6">
                            <label class="labell"><b>Membership Type</b></label> <br />
                        </div>  
                            <div class="col-lg-8 col-xs-6">
                                <div><b>{{membertype}}</b></div> <br />
                            </div>
                       
                  <br /><p></p>
                        <div class="col-lg-3">
                            <label><b>Receipt No/Source</b></label>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID == 2">
                            <input class="form-control" formControlName="ReceiptSourceNumber" type="text" placeholder="Enter Receipt Num/Source Name">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.ReceiptSourceNumber.touched && Form.controls.ReceiptSourceNumber.errors?.required">Receipt Num/Source Name</small>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID != 2">
                            <input class="form-control" formControlName="ReceiptSourceNumber" type="text" placeholder="Enter Receipt Num/Source Name" readonly>
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.ReceiptSourceNumber.touched && Form.controls.ReceiptSourceNumber.errors?.required">Receipt Num/Source Name</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">  <label><b>Receipt Date</b></label></div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID == 2">
                            <input class="form-control" formControlName="ReceiptDate" type="date" name="date" placeholder="Receipt Date">
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID != 2">
                            <input class="form-control" formControlName="ReceiptDate" type="date" name="date" placeholder="Receipt Date" readonly>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Receipt Amount</b></label>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID == 2">
                            <input class="form-control" formControlName="HowMuchPaid" type="text" placeholder="Enter Paid Amount">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.HowMuchPaid.touched && Form.controls.HowMuchPaid.errors?.required">Paid Amount is required</small>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID != 2">
                            <input class="form-control" formControlName="HowMuchPaid" type="text" placeholder="Enter Paid Amount" readonly>
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.HowMuchPaid.touched && Form.controls.HowMuchPaid.errors?.required">Paid Amount is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Membership Number</b></label>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID == 2">
                            <input class="form-control" formControlName="MembershipNum" type="text" placeholder="Membership Num">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.MembershipNum.touched && Form.controls.MembershipNum.errors?.required">MembershipNum is required</small>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID != 2">
                            <input class="form-control" formControlName="MembershipNum" type="text" placeholder="Membership Num" readonly>
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.MembershipNum.touched && Form.controls.MembershipNum.errors?.required">MembershipNum is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Membership Date</b></label>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID == 2">
                            <input class="form-control" formControlName="Dateofmemebership" type="date" placeholder="Date of Membership">
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Dateofmemebership.touched && Form.controls.Dateofmemebership.errors?.required">Date is required</small>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID != 2">
                            <input class="form-control" formControlName="Dateofmemebership" type="date" placeholder="Date of Membership" readonly>
                            <small style="color:red" class="text-danger" *ngIf="Form.controls.Dateofmemebership.touched && Form.controls.Dateofmemebership.errors?.required">Date is required</small>
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b>Volunteer Name</b></label>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID == 2">
                            <input class="form-control" formControlName="VolunteerName" type="text" placeholder="Volunteer Name">
                            <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.VolunteerName.touched && Form.controls.VolunteerName.errors?.required">Volunteer Name is required</small>-->
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID != 2">
                            <input class="form-control" formControlName="VolunteerName" type="text" placeholder="Volunteer Name" readonly>
                            <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.VolunteerName.touched && Form.controls.VolunteerName.errors?.required">Volunteer Name is required</small>-->
                        </div>
                        <div class="col-lg-5"></div>

                        <div class="col-lg-3">
                            <label><b> Volunteer PhNumber</b></label>
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID == 2">
                            <input class="form-control" formControlName="VolunteerPhNumber" type="text" placeholder="Volunteer PhNumber">
                            <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.VolunteerPhNumber.touched && Form.controls.VolunteerPhNumber.errors?.required">Volunteer PhNumber is required</small>-->
                        </div>
                        <div class="col-lg-4" *ngIf="loginDet?.RoleID != 2">
                            <input class="form-control" formControlName="VolunteerPhNumber" type="text" placeholder="Volunteer PhNumber" readonly>
                            <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.VolunteerPhNumber.touched && Form.controls.VolunteerPhNumberp.errors?.required">Volunteer PhNumber is required</small>-->
                        </div>
                        <div class="col-lg-5"></div>

                    </div>
                </fieldset>
                &nbsp;
                <fieldset [formGroup]="Form">
                    <legend>
                        <p class="legend mgt-5">&nbsp;Remarks</p>
                    </legend>
                    <div class="col-lg-3">
                        <label><b>If Any Remarks</b></label>
                    </div>
                    <div class="col-lg-12">
                        <textarea   formControlName="Remarks" placeholder="Enter your Remarks" style="width:100%; padding:10px;" ></textarea>
                        <!-- Adjust error message if needed -->
                        <small style="color:red" class="text-danger" *ngIf="Form.controls.Remarks.touched && Form.controls.Remarks.errors?.required">Remarks are required</small>
 </div>
                     
</fieldset>
                <div class="row">
                    <div class=" col-lg-10"></div>
                    <div class="col-lg-2 mt-3">
                        <input type="Submit" value="Update" class="btn btn-primary border" (click)="onSubmit(Form.value)">
                    </div>
                </div>
            </form>
        </div> <br />
    </div><br />
    </div>

