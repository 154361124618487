import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { generalserverservice } from '../generalserver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OnInit, ViewEncapsulation } from '@angular/core';
 

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
    HomeUrl: any;
    OTP: any;
    OTPtoMobile: any;
    Mobile1: any;
    public form: FormGroup;
    SurName: any;
    Name: any;
    Mobile: any;
    otp: any;
    submitAttempt: boolean | undefined;
    arr: any;
    logindata: any;
    surnamedata: any;
    selectedSurname: any;
    LoginDetails: any;
    otpVerified: any;
    SelforDatabaseReg: number;

    constructor(public router: Router, public fb: FormBuilder, public generalService: generalserverservice, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        debugger
        this.logindata = localStorage.getItem('LoginDetails');
        this.LoginDetails = JSON.parse(this.logindata)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            debugger
            this.HomeUrl = data.Webservice;
        });
        this.form = fb.group({
            SurName: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(20)])],
            Name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
            Mobile: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
            // otp: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],

        });

    }
    Register() {
        this.router.navigate(['/detailsedit']);
    }


    ngOnInit() {
        this.Getsurname();
    }

    Getsurname() {

        debugger
        var url = "api/Events/GetSurNames";
        this.generalService.GetData(url).then(data => {
            debugger
            this.surnamedata = data;


            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    onSurnameSelection(value: string) {
        this.selectedSurname = value;
        // You can also set the value to your form control here if needed:
        // this.Form.controls['Surname'].setValue(value);
    }

    onSubmit1(value: any) {
        debugger
        this.submitAttempt = true;
        this.arr = [];

        // Assuming value contains the form data, make sure to access its properties correctly
        this.arr.push({
            Surname: value?.SurName, // Check if value is structured correctly
            Name: value?.Name,
            Mobile: value?.Mobile,
            // Add other fields as needed
        });
        this.router.navigate(['/Newprofiledetails', { UserDetails: JSON.stringify(this.arr[0].Mobile) }])
            .then(() => {
                window.location.reload();
            });

    }


    onSubmit(value: any) {
       
        debugger;
        this.submitAttempt = true;
        this.arr = [];

        // Assuming value contains the form data, make sure to access its properties correctly
        this.arr.push({
            Surname: value?.SurName, // Check if value is structured correctly
            Name: value?.Name,
            Mobile: value?.Mobile,
            SelforDatabaseReg: 'selfreg'
            // Add other fields as needed
        });

        debugger;

        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(this.arr));
        formData.append('Flag', '1');
        const url = "api/Events/registration_Crud_new";
        //const routes: Routes = [
        //    // Other routes...
        //    { path: 'detailsedit', component: Newprofiledetails }, // Make sure to define DetailsEditComponent
        //];

        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            if (data && data) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", value?.Mobile);
                var url = 'api/Events/checking_Mobile';
                this.generalService.PostData(url, UploadFile).then((data1: any) => {
                    var loginDataString = JSON.stringify(this.arr);


                    localStorage.setItem("LoginDetails", loginDataString);

                  //  this.generalService.ShowAlert('Success', 'Your registration completed successfully.', 'success');
                    localStorage.setItem('userData', (this.arr));
                    debugger;
                    /* this.router.navigate(['/Newprofiledetails', { userdet: data }])*/

                    this.router.navigate(['/Newprofiledetails', { UserDetails: JSON.stringify(this.arr[0].Mobile) }])
                        .then(() => {
                            window.location.reload();
                        });
                    //this.router.navigate(['/EditProfile', { Mobile: this.form.value.Mobile }]);
                    // this.navCtrl.pop();
                })
            }
        });
    }

    //SendOTPtoMobile(value: any) {
    //    debugger
    //    if (value.Mobile != null || value.Mobile != undefined) {
    //    if (value.Mobile.length == 10) {
    //        debugger
    //        this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
    //        var Uploadfile = new FormData();
    //        Uploadfile.append("MobileNo", value.Mobile)
    //        Uploadfile.append("OTP", this.OTPtoMobile)
    //        var url = "api/Events/SendOtpToMobile";
    //        this.generalService.PostData(url, Uploadfile).then((data: any) => {

    //        })
    //        }
    //    }
    //}

    // otpVerified: boolean = false;
    onChange(event: any) {
        this.Mobile = event.target.value;
        var uploadfile = new FormData();
        uploadfile.append("Param", this.Mobile)
        var url = "api/Events/Checking_Email_And_Mobile_2022"
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            if (data == "EXIST") {
                this.generalService.ShowAlert('ERROR', 'This number is already registered Please login', 'error');

            }
        })
    }
    SendOTPtoMobile(value: any) {
        debugger
        if (value.Mobile != null || value.Mobile != undefined) {
            if (value.Mobile.length == 10) {
                debugger
                this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
                var Uploadfile = new FormData();
                Uploadfile.append("MobileNo", value.Mobile)
                Uploadfile.append("OTP", this.OTPtoMobile)
                var url = "api/Events/SendOtpToMobile";
                this.generalService.PostData(url, Uploadfile).then((data: any) => {

                })
            }
        }
        // Do not set otpVerified to true here, wait for OTP verification
    }

    verifyOTP(enteredOTP: string) {
        if (enteredOTP === this.OTPtoMobile.toString()) {
            this.otpVerified = true;
        } else {
            this.otpVerified = false;
        }

    }
}
function matchingPasswords(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
