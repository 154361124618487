import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { generalserverservice } from '../generalserver.service'
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-searchlist',
    templateUrl: './searchlist.component.html',
    styleUrls: ['./searchlist.component.scss']
})

export class SearchlistComponent {
    KeyReferences: any;
    FunctionalActivity: any;
    whatsAppProductURL: string;
    ProductName: any;
    HomeUrl: any;
    dataResult: any = [];
    arr: any[];
    addNew: boolean;
    dataResult1: any = [];
    dataResult11: any = [];
    ProductID: any;
    Tests: any[];
    EnquiryAry: any[];
    filteredData: any;
    searchFilter: string = '';
    ID: any;
    selectedOption: string = '';
    yesCount: number = 0;
    yesButtonDisabled: any;
    logindata: any;
    private searchSubject = new Subject<string>();
    filteredDataLength: any;
    approvedStatus: boolean = false;
    showDetails = false;
    flag: any;
    button: any;
    clickCountElement: any;
    LoginDetails: any; LoginDetails1: any;
    ShowInfo: any;
    /* scroll: any;*/
    Enqueries11: any[] = [];
    items: any;
    rowcount12: any;
    //currentPage:any = 1; // Initial page number
    //pageSize:any = 50;
    //loadingMoreData: any;
    AllUsers: any;
    AdminMobile: any;
    dataResult2: any;
    isVolunteer: boolean;
    notApprovedTabSelected: boolean = false;
    approvedTabSelected: boolean = true; // Set to true by default
    rowcountd: any;
    Volunteer: any;
    rowData: any;
    loadingController: any;
    changeDetectorRef: any;
    constructor(public router: Router, public generalService: generalserverservice, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        debugger
        this.logindata = localStorage.getItem('LoginDetails');
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        //if (this.LoginDetails == null) {
        //    this.AdminMobile == this.LoginDetails.Mobile;
        //}
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

        this.GetUser(3);

    }


    toggleNotApprovedTab() {
        debugger
        this.notApprovedTabSelected = true;
        this.approvedTabSelected = false;

    }

    toggleApprovedTab() {
        this.notApprovedTabSelected = false;
        this.approvedTabSelected = true;
        //window.location.reload();
        //if (val==1) {
        //    this.rowcount12 = this.filteredData.length;
        //}
    }
    ngOnInit() {
        this.GetUserDataForDownload();
        /*this.GetEnqueries();*/
        // this.GetUser(3);
        //  this.GetUserSearching();
        // this.GetApprovalStatus();
        debugger
        //this.searchSubject.pipe(
        //    debounceTime(300), // Adjust the debounce time as needed
        //    distinctUntilChanged()
        //).subscribe(() => {

        //});

        const storedCount = localStorage.getItem('yesCount');
        if (storedCount) {
            this.yesCount = parseInt(storedCount, 10);
        }

    }
    doRefresh(event: any) {
        debugger
        console.log('Begin async operation');
        this.mydetails(this.ID);



        setTimeout(() => {
            console.log('Async operation has ended');
            console.log();
            event.target.complete();
        }, 2000);
    }
    async refreshPage() {

        const loading = await this.loadingController.create({
            message: 'Refreshing...',
            spinner: 'circles',
            translucent: true,
            backdropDismiss: false,
        });

        await loading.present();

        setTimeout(() => {
            this.ngOnInit(); // Call ngOnInit directly to reset the page
            loading.dismiss();
            this.changeDetectorRef.detectChanges();
        }, 1000);
    }
    // script.ts 

    Registration() {
        debugger
        this.router.navigate(['/Registration',]);
    }

    updateCount() {
        this.generalService.ShowAlert("d", "s", "d")
        if (this.selectedOption === 'yes') {
            this.yesCount++;
            // Store the updated count in localStorage
            localStorage.setItem('yesCount', this.yesCount.toString());
        }
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
    }

    alphabetLetters: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split("");
    filterByAlphabet(letter: string) {
        debugger
        // Filter data based on the selected alphabet
        this.filteredData = this.dataResult11.filter(item =>
            item.Name.toLowerCase().startsWith(letter.toLowerCase())
            //item.State.toLowerCase().startsWith(letter.toLowerCase()) ||
            //item.Address.toLowerCase().startsWith(letter.toLowerCase()) ||
            //item.City.toLowerCase().startsWith(letter.toLowerCase())
        );

        this.filteredDataLength = this.filteredData.length;
    }



    GetDataAscDesc() {
        debugger
        if (this.flag == 11111 || this.flag == undefined) {
            this.flag = 111;
            //this.GetUser(5);
        }
        else if (this.flag == 111) {
            this.flag = 11111;
            //this.GetUser(4);
        }
    }


    GetUser(val: any) {
        debugger
        this.arr = []
        this.arr.push({
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", val);
        var url = "api/Events/SearchContactList";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult11 = data;
            //this.filteredData = data;
            this.approvedStatus = true;
            this.getApprovel(this.approvedStatus);
            this.rowcount12 = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    GetApprovalStatus() {
        debugger
        this.arr = []
        this.arr.push({
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '3');
        var url = "api/Events/Searching";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger;
            this.dataResult = data;
            this.approvedStatus = false;
            this.getApprovel(this.approvedStatus);
            //  this.filteredData = this.dataResult;
            this.filteredDataLength = this.filteredData.length;
            this.rowcount12 = this.dataResult[0].rCount;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }



    GetUserSearching() {
        debugger
        this.arr = []
        this.arr.push({
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '1');
        var url = "api/Events/Searching";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult = data;
            this.filteredData = this.dataResult;
            this.filteredDataLength = this.filteredData.length;
            this.rowcount12 = this.dataResult[0].rCount;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    getApprovel(approvedStatus: any) {
        debugger
        if (approvedStatus == false) {
            this.filteredData = this.dataResult11.filter((a: any) => a.Approval1 != 1 || a.Approval2 != 1 || a.Approval3 != 1);
            this.Volunteer = this.dataResult11.isVolunteer
            this.rowcountd = this.filteredData.filter((a: any) => a.Approval1 != 1 && a.Approval2 != 1 && a.Approval3 != 1);
            this.rowcount12 = this.rowcountd.length

        } else {
            this.filteredData = this.dataResult11.filter((a: any) => a.Approval3 === approvedStatus)
            this.rowcount12 = this.filteredData.length;

        }

    }
    mydetails(ID: any) {
        debugger
        if (!this.LoginDetails) {
            this.router.navigate(['/Login']);
        } else {
            this.router.navigate(['/Userdetails', { ID: ID }]);
        }
    }


    onSearchInput(event: any) {
        debugger
        this.searchSubject.next(event.target.value);
        this.filteredData = this.dataResult11.filter(item =>

            // Adjust the condition based on your specific filtering requirements
            item.FullName.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
            item.Surname.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
            item.Name.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
            item.Mobile.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
            item.CityName.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
            item.State.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
            item.DistrictName.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
            item.Address.toLowerCase().includes(this.searchFilter.toLowerCase())
        );
        const numberOfFilteredRecords: number = this.filteredData.length;
        this.filteredDataLength = numberOfFilteredRecords;
    }


    GetUserDataForDownload() {
        debugger
        var obj = [{

        }]
        var UploadFile = new FormData();
        UploadFile.append('Param', JSON.stringify(obj));
        UploadFile.append('Flag', '3');
        var url = "api/Events/SearchContactList";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.AllUsers = data;
            //Name: `${row.Name} ${row.Surname}`,
        })
    }


    downloadAsExcel(): void {
        debugger
        import("xlsx").then((xlsx) => {
            const dataToExport = this.AllUsers.map((row: any) => ({
                Name: row.Name,
                Surname: row.Surname,
                Father: row.Father,
                CurrentAddress: row.Address,
                NativeAddress: row.OriginalAddress,
                Phonenumber: row.Mobile,
                AlterNativePhonenumber: row.AlternativeMobile,
                Age: row.Age,
                DateOfBirth: row.DateOfBirth,
                Occupation: row.Occupation,
                OfficeAddress: row.CompanyAddress,
                Email: row.Email,
                MembershipType: row.MembershipDetails,
                DateofMembership: row.Date,
                MembershipNo: row.MembershipNum,
                Remarks: row.Remarks,
            }));

            const worksheet = xlsx.utils.json_to_sheet(dataToExport);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Save the Excel file
            xlsx.writeFile(workbook, 'exported_data.xlsx');
        });
    }


    deleteitem(val: any) {
        debugger
        // Delete item without confirmation
        this.arr = [];
        this.arr.push({
            ID: val
        });
        debugger
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '6');
        var url = "api/Events/SearchContactList";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;
            if (data == 'SUCCESS') {
                this.generalService.ShowAlert('SUCCESS', 'Donor deleted successfully', 'success');
                window.location.reload();
            }
            () => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
            }
        });
    }


}
