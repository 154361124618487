<div id="about" class="about-area ptb-100">
    <div class="container">
        <div id="Registration">
            <div class="row">
                <div class="col-lg-3"></div>
                <div class="col-lg-6">
                    <div class="card">

                        <form [formGroup]="form">
                            <div class="form-group">
                                <label><b> Surname<span style="color:red">*</span></b></label>
                                <select class="form-control" formControlName="SurName" (change)="onSurnameSelection($event.target.value)">
                                    <option value="">Select Surname</option>
                                    <option *ngFor="let surname of surnamedata" [value]="surname.Surname">{{ surname.Surname }}</option>
                                </select>
                                <small style="color:red" class="text-danger" *ngIf="form.controls.SurName.touched && form.controls.SurName.errors?.required">Surname is required</small>
                            </div>
                            <div>
                                <label for="name"><b>Full Name<span style="color:red">*</span></b> </label>
                                <input type="text" id="name" formControlName="Name" placeholder="Enter Your Name" name="name" required>
                                <small style="color:red" class="text-danger" *ngIf="form.controls.Name.touched && form.controls.Name.errors?.required">Name is required</small>
                            </div>

                            <div class="row">
                                <div class="col-lg-8 col-xs-6">
                                    <label for="mobile"><b>Mobile number<span style="color:red">*</span></b> </label>
                                    <input type="text" inputmode="numeric" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10)" [maxlength]="10" placeholder="Enter Your Mobile" id="mobile" name="mobile" required formControlName="Mobile" (change)="onChange($event)">
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Mobile.touched && form.controls.Mobile.errors?.required">Mobile is required</small>
                                </div>
                                <div class="col-lg-4 col-xs-6">
                                    <label></label>
                                    <div class="btn_otp" (click)="SendOTPtoMobile(form.value)">Send OTP</div>
                                </div>
                            </div>
                            <label for="otp" *ngIf="OTPtoMobile!=null"><b>OTP<span style="color:red">*</span></b></label>
                            <input placeholder="Enter Otp" type="text" id="otp" name="otp" maxlength="6" *ngIf="OTPtoMobile!=null" (blur)="verifyOTP($event.target.value)">

                        </form>
                        <div>


                            <button type="submit" class="sign_up" [disabled]="!form.valid || !otpVerified" (click)="onSubmit(form.value)"> Sign Up</button>

 
                        </div>
                    </div>
                </div>
                <div class="col-lg-3"></div>
            </div>
        </div>
        <br />
        <br />
    </div>
    </div>
