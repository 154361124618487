 


import { Component, OnInit, HostListener ,AfterViewInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
    logindata: any;

    constructor(private viewportScroller: ViewportScroller, private router: Router) {
        debugger
       // this.logindata = JSON.parse(localStorage.getItem('LoginDetails'));
    }
    ngAfterViewInit() {
        debugger
        this.logindata = JSON.parse(localStorage.getItem('LoginDetails'));
    }

    // Navbar Sticky
    isSticky: boolean = false;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.isSticky = scrollPosition >= 50;
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() { }

    logout() {
        localStorage.removeItem('LoginDetails');
        this.router.navigateByUrl('/Login');
        this.logindata = null;
    }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    Registration() {
        // Implement the logic to navigate to the Registration page
        this.router.navigate(['/Registration']);
    }

    loginRedirect() {
        // Check if the user is registered or not
        if (this.isUserRegistered()) {
            // If registered, navigate to the home page
            this.router.navigate(['/']);
        } else {
            // If not registered, navigate to the registration page
            this.router.navigate(['/Registration']);
        }
    }

    private isUserRegistered(): boolean {
        // Implement your logic to check if the user is registered.
        // You might want to replace the following line with your actual logic.
        return !!this.logindata;
    }
}

