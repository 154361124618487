<div class="container">
    <div id="mydetails">
        <div class="row">
            <div class="col-lg-6 col-xs-6">
                <button class="btn btn-primary" (click)="goto()" style="float:left">back</button>
            </div>
            <div class="col-lg-6 col-xs-6" *ngIf="ID==LoginDetails.ID || LoginDetails.RoleID === 2 || LoginDetails.RoleID === 1 || Volunteer == 'true' ">
                <button class="btn btn-primary" style="float:right" (click)="clickonEdit ()">
                    Edit
                    <i class="fas fa-edit"></i>
                </button>
            </div>
        </div>

        <br />
        <fieldset>
            <legend>
                <p class="legend mgt-5">&nbsp;Personal Details</p>
            </legend>

            <div class="row">
                <div class="col-lg-8">

                    <!--<div><h5 class="icon" style="text-transform:capitalize;">{{Surname}}   &nbsp; {{Name}}</h5></div><br />-->
                    <div class="row">
                        <div class="col-lg-4 col-xs-6 "><label class="labell"><b>Full Name</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border icon" style="text-transform:capitalize;"><p class="ppp">{{Surname}} &nbsp; {{Name}} </p></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-xs-6 "><label class="labell"><b>Date Of Birth</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{DateOfBirth}} </p></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-xs-6"><label class="labell"><b>Age</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{Age}} </p></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-xs-6 "><label class="labell"><b>Father's Name</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{Father.toLowerCase()}}</p></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-xs-6"><label class="labell"><b>Current Address </b></label> </div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{Address}}</p></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-6"><label class="labell"><b>Native Address</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{OriginalAddress}}</p></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-6"><label class="labell"><b>Mobile Number</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp"><a class="mobilenum " href="tel:{{mobileno}}">{{mobileno}}</a></p></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4  col-xs-6"><label class="labell"><b>Alternate Mobile</b></label> </div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{AlternativeMobile}}</p></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-6"><label class="labell"><b>Occupation/Employment</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{Occupation}}</p></div>
                    </div>
                    <!--<div class="row">
                        <div class="col-lg-4 col-xs-6"><label class="labell"><b>Office Address</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{CompanyAddress}}</p></div>
                    </div>-->
                    <div class="row">
                        <div class="col-lg-4 col-xs-6"><label class="labell"><b>Since How Long Staying Here</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppp">{{StayDuration}}</p></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4  col-xs-6"><label class="labell"><b>Aadhar Number</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-4 col-xs-12 border"><p class="ppp">{{Idproof}}</p></div>
                        <div class="col-lg-2 col-xs-6" (click)="OpenImage(IdProofImage)"><img src="{{ HomeUrl + IdProofImage }}" height="30" width="30" /></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4  col-xs-6"><label class="labell"><b>Email ID</b></label></div>
                        <div class="col-lg-2 col-xs-6"></div>
                        <div class="col-lg-6 col-xs-12 border"><p class="ppr email-container">{{Email}}</p></div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <!--<div *ngIf="LoginDetails?.RoleID === 1" style="margin-left:25px">
                        <span><b>Is a Volunteer?</b></span>&nbsp;
                        <input type="checkbox" [(ngModel)]="isVolunteer" />
                    </div>-->

                    <img class="image" src="{{Image}}" style="float: right;" *ngIf="Image !== '' && Image !== null && Image !== undefined" />
                    <img class="image" src="../../assets/img/Logo.png" style="float: right;" *ngIf="Image === '' || Image === null || Image === undefined" />
                </div>

            </div>
        </fieldset>
        <br />
        <br />
        <fieldset *ngIf="Approval3 === true || Volunteer == 'true' || (Approval3 === false && (LoginDetails?.RoleID === 2 || LoginDetails?.RoleID === 3 || LoginDetails?.RoleID === 4 || LoginDetails?.RoleID === 5))">
            <legend>
                <p class="legend mgt-5">&nbsp;Membership Details</p>
            </legend>
            <form class="form_1" [formGroup]="form" (ngSubmit)="Donersform()">

                <div class="row">
                    <div class="col-lg-4">
                        <label for="username">Membership Type<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mb-1">
                        <div class="row" id="genderChipsRow">
                            <ng-container *ngFor="let type of AllTypes">
                                <div class="col-lg-3 col-xs-6">
                                    <div class="chip1" (click)="selectGender(type.MemberType)" [ngClass]="{'selected': selectedGender === type.MemberType}">{{ type.MemberType }}</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-3">
                        <label for="mobile">Receipt No/Source<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <input type="text" id="ReceiptSourceNumber" formControlName="ReceiptSourceNumber" placeholder="Receipt Source Number"
                               [readonly]="Volunteer !== 'true'" />
                        <small style="color:red" class="text-danger" *ngIf="form.controls.ReceiptSourceNumber.touched && form.controls.ReceiptSourceNumber.errors?.required">Donor Mobile is required</small>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <label for="mobile">Receipt Date<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <input style="padding:6px" formControlName="ReceiptDate" required type="date" name="ReceiptDate" id="ReceiptDate"
                               [readonly]="Volunteer !== 'true'" />
                    </div>

                    <div class="col-lg-4 mt-3">
                        <label for="email">Reciept Amount<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <input type="text" id="HowMuchPaid" formControlName="HowMuchPaid" placeholder="How Much Paid"
                               [readonly]="Volunteer !== 'true'" />
                        <small style="color:red" class="text-danger" *ngIf="form.controls.HowMuchPaid.touched && form.controls.HowMuchPaid.errors?.required">Receipt Amount is required</small>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <label for="mobile">Membership&nbsp;Number<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <input type="text" id="MembershipNum" formControlName="MembershipNum" placeholder="MembershipNum"
                               [readonly]="Volunteer !== 'true'" />
                        <small style="color:red" class="text-danger" *ngIf="form.controls.MembershipNum.touched && form.controls.MembershipNum.errors?.required">Membership Number is required</small>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <label for="email">Memebership Date<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <div class="input-group">
                            <input style="padding:6px" formControlName="Dateofmemebership" required type="date" name="Dateofmemebership" id="Dateofmemebership"
                                   [readonly]="Volunteer !== 'true'" />
                        </div>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <label for="email">Remarks</label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <textarea type="text" id="Remarks" formControlName="Remarks" placeholder="Remarks"
                                  [readonly]="Volunteer !== 'true'"></textarea>
                        <small style="color:red" class="text-danger" *ngIf="form.controls.Remarks.touched && form.controls.Remarks.errors?.required">Remarks is required</small>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <label for="email">Volunteer Name<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <input type="text" id="VolunteerName" formControlName="VolunteerName" placeholder="Volunteer Name"
                               [readonly]="Volunteer !== 'true'" />
                        <small style="color:red" class="text-danger" *ngIf="form.controls.VolunteerName.touched && form.controls.VolunteerName.errors?.required">Volunteer Name is required</small>
                    </div>


                    <div class="col-lg-4 mt-3">
                        <label for="mobile">Volunteer&nbsp;Number<span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 mt-3">
                        <input type="text" id="VolunteerPhNumber" formControlName="VolunteerPhNumber" inputmode="numeric" pattern="[0-9]*" maxlength="10" placeholder="VolunteerPhNumber"
                               [readonly]="Volunteer !== 'true'" />
                        <small style="color:red" class="text-danger" *ngIf="form.controls.VolunteerPhNumber.touched && form.controls.VolunteerPhNumber.errors?.required">Volunteer Phone Number is required</small>
                    </div>
                </div>
                <div class="col-lg-3" *ngIf="LoginDetails?.RoleID === 2 || LoginDetails?.RoleID === 3 || LoginDetails?.RoleID === 4 || LoginDetails?.RoleID === 5 || Volunteer == 'true'">
                    <button type="submit" class="btn btnn">Submit</button>
                </div>
            </form>
        </fieldset>

        <br />

        <fieldset>
            <legend>
                <p class="legend mgt-5">&nbsp;Approvals</p>
            </legend>

            <div class="row " *ngIf="LoginDetails.RoleID == 1 ||LoginDetails.RoleID == 3 || LoginDetails.RoleID == 4">
                <legend>
                    <p class="legend mgt-5">&nbsp;Approvals</p>
                </legend>
                <div class="col-lg-4">
                    <button [disabled]="approvalDone || !isRole3Enabled" class="btn btn-success" (click)="Approved1('Approved','Approval1','true','Approved')" style="padding:5px;font-size:13px;">Approve</button><br />
                    <label class="labell">Alla Suri Apparao</label>
                </div>

                <div class="col-lg-4">
                    <button [disabled]="approvalDone || !isRole4Enabled" class="btn btn-success" (click)="Approved1('Approved','Approval2','true','Approved')" style="padding:5px;font-size:13px;">Approve</button><br />
                    <label class="labell">Malla Adhi Lakshmi</label>
                </div>

                <div class="col-lg-4">
                    <button [disabled]="!enabled" class="btn btn-success" (click)="approveClicked = true; Approved1('Approved','Approval3','true', 'Approved')" style="padding:5px;font-size:13px;">Approve</button><br />
                    <label class="labell">Dadi Nagendra Kumar</label>
                </div>
            </div>

            <div class="row " *ngIf="LoginDetails.RoleID == 5">
                <legend>
                    <p class="legend mgt-5">&nbsp;Approvals</p>
                </legend>
                <div class="col-lg-4">
                    <button [disabled]="approvalDone || !isRole3Enabled" class="btn btn-success" (click)="Approved1('Approved','Approval1','true','Approved')" style="padding:5px;font-size:13px;">Approve</button><br />
                    <label class="labell">Alla Suri Apparao</label>
                    <h5 *ngIf="approvalDone">He is Approved</h5>
                </div>

                <div class="col-lg-4">
                    <button [disabled]="approvalDone || !isRole4Enabled" class="btn btn-success" (click)="Approved1('Approved','Approval2','true','Approved')" style="padding:5px;font-size:13px;">Approve</button><br />
                    <label class="labell">Malla Adhi Lakshmi</label>
                    <h5 *ngIf="approvalDone">He is Approved</h5>
                </div>

                <div class="col-lg-4">
                    <button class="btn btn-success"
                            (click)="approveClicked = true; Approved1('Approved','Approval3','true', 'Approved')"
                            style="padding:5px;font-size:13px;"
                            [disabled]="approvalDone || !(Approval1 && Approval2)">
                        Approve
                    </button><br />
                    <label class="labell">Dadi Nagendra Kumar</label>
                    <h5 *ngIf="approvalDone">He is Approved</h5>
                </div>

            </div>

            <div class="row" *ngIf="Approval1 && Approval2 && Approval3">
                <div class="col-lg-6 col-12">
                    <div class="card" *ngFor="let item of dataResult" id="cardContent" #cardContent>
                        <div class="row">
                            <div class="col-lg-2 col-2">
                                <img src="../../../../assets/img/Gouri.jpeg" class="card_logo">
                            </div>
                            <div class="col-lg-10 col-10">
                                <h4 class="text-center text-uppercase pt-3 cardheadding pb-1">Sree Gouri(Gavara) Seva Sangham, Hyd</h4>
                                <p class="text-center" style="font-size:12px;">Regd. no. 905/91</p>
                                <p class="text-center  text-xs-left" style="font-size:13px;">Vanitha Enclave,Trimulgherry,Secundrabad-5000015 </p>

                            </div>
                            <h6 class="pb-1 pt-2"><b>Voter Id: {{VoterId}}</b></h6>

                            <div class="col-lg-4 col-4">
                                <img src="{{item.Image}}" alt="profile" class="card_img" />

                            </div>
                            <div class="col-lg-8 col-8">
                                <div class="row">
                                    <div class="col-lg-5 col-xs-6 cardname">Name </div>
                                    <div class="col-lg-7 col-xs-6 cardname" style="margin-left:-28px;">{{item.Surname}} {{item.Name}}</div>
                                    <div class="col-lg-5 col-xs-6 cardname"> Fathers Name </div>
                                    <div class="col-lg-7 col-xs-6 cardname" style="margin-left:-28px;">{{item.Father}}</div>
                                    <div class="col-lg-5 col-xs-6 cardname">Mobile </div>
                                    <div class="col-lg-7 col-xs-6 cardname" style="margin-left:-28px;">{{item.Mobile}}</div>
                                    <div class="col-lg-5 col-xs-6 cardname"> Address </div>
                                    <div class="col-lg-7 col-xs-6 cardname" style="margin-left:-28px;">{{item.Address}}</div>
                                    <!--<div class="col-lg-5"> Gender </div>-->
                                    <!--<div class="col-lg-7" style="margin-left:-28px;">{{item.GenderName}}</div>-->
                                    <div class="col-lg-5 col-xs-6 cardname"> DOB </div>

                                    <div class="col-lg-7 col-xs-6 cardname" style="margin-left:-28px;">{{item.DateOfBirth}}</div>
                                </div>

                            </div>

                        </div><br />
                        <div class="row">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-8 col-xs-8 col-10">
                                <h5 class="electors">e-Electors Photo Identity Card</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-11 col-12">
                        <button (click)="downloadAsImage()" class="btn btnprimary btn-sm">Download e-Electors Photo Identity Card</button>
                    </div>

                </div>
                <div class="col-lg-4"></div>
                <div class="col-lg-4"></div>
            </div>
            <br />


        </fieldset>
        <!--<div class="row">
            <div class=" col-lg-10"></div>
            <div class="col-lg-2 mt-3">
                <input type="Submit" value="Submit" class="btn" [ngClass]="{'btn-primary': buttonClicked, 'border': buttonClicked}" (click)="onSubmit(Form.value)">
            </div>
        </div>-->

    </div>
</div>
<br />

<style>
    @media only screen and (max-width:600px) {
        #mydetails {
            margin-top: 22%;
        }

        .icon {
            font-size: 16px;
        }
    }

    .card {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
    }

        .card:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }

    .container {
        padding: 2px 16px;
    }
</style>

